<template>
  <div
    v-if="Store.confirmExitModalIsActive"
    class="exit-modal"
  >
    <ModalCont
      width="300px"
      @close="Store.confirmExitModalIsActive = false"
    >
      <div class="text">
        Вы уверены?
      </div>
      <GlobusButtonPrimary
        :text="'Выйти из аккаунта'"
        class="btn"
        @click="exit()"
      />
    </ModalCont>
  </div>
</template>

<script setup>
import { useStore } from '@/store';
import ModalCont from '@/components/common/ModalCont.vue'
import GlobusButtonPrimary from '@/components/common/GlobusButtonPrimary.vue';
import { exit } from '@/composables/functionExit';
const Store = useStore()

</script>

<style lang="scss" scoped>

.exit-modal {
  position: fixed;
  z-index: 9999;
}

.text {
    color: #1F9AFF;
    font-size: 28px;
    line-height: 120%;
    margin-bottom: 70px;
    margin-top: 17px;
    text-align: center;
}

.btn {
    margin: 0 auto 25px;
}
</style>