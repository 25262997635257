<script setup>
import RouterItem from "@/components/GlobusHeader/HeaderProject/ProjectHeaderRouting/RouterItem.vue";
import { useStore } from '@/store'
import {computed, onMounted, onUpdated, ref} from "vue";
import {useRouter} from "vue-router";
import {useProjects} from "@/store/projects";
import RouterItemProxy from "@/components/GlobusHeader/HeaderProject/ProjectHeaderRouting/RouterItemProxy.vue";

const Store = useStore()
const router = useRouter()

const projectStore = useProjects()

onMounted(() => {
  isProd.value = !window.location.href.includes(':80') && !window.location.href.includes(':7777')

})

onUpdated(() => {
  if (Store.length > 0) {
    for (let route of Store.routing) {
      if (route.route === '/map') {
        route.disabled = projectStore.activeProject?.mapFiles?.length > 0
      }
    }
  }
})

const isProd = ref(false)

</script>

<template>
  <div
    v-if="(Store.activeModule.dataName === 'module_globus')
      && router.currentRoute.fullPath !== '/login'
      && Store.routing?.length"
    class="routing"
  >
    <div
      v-for="(link, index) of Store.routing"
      :key="index"
      class="routing-link"
      :class="{ 'no-active': link.text_ru.toLowerCase() !== 'новости'
        && link.text_ru.toLowerCase() !== 'пульс'
        && link.text_ru.toLowerCase() !== 'аналитика' && isProd }"
    >
      <RouterItem
        v-if="!link.disabled || (isMapActive && link.text_ru.toLowerCase() === 'карта')"
        :link="link"
      />
    </div>
  </div>
  <div
    v-if="Store.activeModule.dataName === 'module_proxy'"
    class="routing"
  >
    <div
      v-for="(link, index) of Store.routing"
      :key="index"
    >
      <RouterItemProxy
        v-if="!link.disabled"
        :link="link"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.routing {
  display: flex;

  .routing-link {
    &.no-active {
      pointer-events: none;
    }
  }
}
</style>