import ru from '@/../public/lang/ru.yaml'
import en from '@/../public/lang/en.yaml'
import {useStore} from "@/store";

window.globusDictionary = {
    ...ru,
    ...en
};

export const locales = [
    {
        code: 'ru',
        name: 'Русский',
    },
    {
        code: 'en',
        name: 'English',
    },
    {
        code: 'fr',
        name: 'Французский',
    },
    {
        code: 'es',
        name: 'Испанский',
    },
    {
        code: 'ar',
        name: 'Аргентинский',
    },
];

export const t = useGlobusLocal().t;

export function useGlobusLocal() {
    function initGlobusLocal(code) {
        setLocale(code);
    }

    async function setLocale(code) {
        const storeLK = useStore()

        if (storeLK.language !== code) {
            storeLK.language = locales.find((l) => l.code === code);
            localStorage.setItem('globusLanguage', code)
            const event = new CustomEvent("ChangeLanguage", { detail: {code: code} });
            window.dispatchEvent(event);
        }
    }

    function t(msg) {
        try {
            return msg
                .split('.')
                .reduce((val, part) => val[part], window.globusDictionary[window.storeLK.language.code]['view']['personal_area']);
        } catch (e) {
            console.log('Отсутствует перевод для:', msg)
        }
    }

    return {
        t,
        module,
        locales,
        initGlobusLocal,
        setLocale,
    };
}

const translateModuleFunction = (moduleName) => {
    return function t(msg) {
        try {
            return msg
                .split('.')
                .reduce((val, part) => val[part], window.globusDictionary[window.storeLK.language.code]['view'][moduleName]);
        } catch (e) {
            console.log(e)
        }

    }
}

window.useGlobusLocal = translateModuleFunction