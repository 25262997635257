<template>
  <LKProjectWindow v-if="store.showMode === 'projects'" />
  <LKProfileWindow v-if="store.showMode === 'settings'" />
  <LKServiceWindow v-if="store.showMode === 'services'" />
  <LKProjectWindow
    v-if="store.showMode === 'archive'"
    :is-archive="true"
  />
</template>

<script setup>
import LKProjectWindow from "@/pages/LKPage/LKWindow/LKProjectWindow.vue";
import LKServiceWindow from "@/pages/LKPage/LKWindow/LKServiceWindow.vue";
import LKProfileWindow from "@/pages/LKPage/LKWindow/LKProfileWindow.vue";

import { useStore } from '@/store';
const store = useStore()
</script>

<style scoped lang="scss">
.window-wrapper {
  display: flex;
  flex-direction: column;

  gap: 33px;

  .header {
    display: flex;
    justify-content: space-between;

    &__title {
      font-size: 28px;
      font-weight: 400;
    }

    &__search {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>