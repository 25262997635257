<script setup>
</script>

<template>
  <div
    class="header-background"
  />
</template>

<style scoped lang="scss">
.header-background {
  z-index: 1999;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
</style>