<script setup>
import {defineProps, ref, defineEmits, onMounted, watch, provide} from 'vue';
import {useUser} from "@/store/user";
import {useProjects} from "@/store/projects";
import {createProjectForm} from "@/composables/constans/formData";
import ProjectArchive from "@/components/LKPage/LKWindow/ProjectWindow/ProjectModal/ProjectArchive.vue";
import ProjectHeader from "@/components/LKPage/LKWindow/ProjectWindow/ProjectModal/ProjectHeader.vue";
import ProjectName from "@/components/LKPage/LKWindow/ProjectWindow/ProjectModal/ProjectName.vue";
import ProjectModuleList from "@/components/LKPage/LKWindow/ProjectWindow/ProjectModal/ProjectModuleList.vue";
import ProjectCountry from "@/components/LKPage/LKWindow/ProjectWindow/ProjectModal/ProjectCountry.vue";
import ProjectControl from "@/components/LKPage/LKWindow/ProjectWindow/ProjectModal/ProjectControl.vue";
import BlockView from "@/components/common/BlockView.vue";

onMounted(async () => {
  await userStore.getUser().then(() => {
    createProjectFormData.value.users.push(userStore.user?.userId)
  })
  await userStore.getOrganizationMembers()
})

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  isEdit: {
    type: Boolean,
    default: false
  },
  isArchive: {
    type: Boolean,
    default: false
  },
  project: {
    type: Object,
    default: null
  }
})

const emit = defineEmits(['closeModal', 'updateProjectList'])

const userStore = useUser()
const projectStore = useProjects()

const errorList = ref([])
provide("errorList", errorList)

const createProjectFormData = ref(JSON.parse(JSON.stringify(createProjectForm)))
provide("createProjectFormData", createProjectFormData)

const selectedRegionList = ref([])
provide("selectedRegionList", selectedRegionList)

watch(() => props.show, async (status) => {
  errorList.value = []
  if (status === false) {
    createProjectFormData.value = JSON.parse(JSON.stringify(createProjectForm))
  } else {
    if (props.isEdit) {
      createProjectFormData.value = JSON.parse(JSON.stringify(props.project))
      selectedRegionList.value = await projectStore.getProjectMapList(props.project.id);
    }
  }
})

</script>

<template>
  <BlockView
    v-if="props.show"
    @click="emit('closeModal')"
  />

  <div
    v-if="props.show"
    class="modal-create-project"
  >
    <ProjectHeader
      :is-edit="props.isEdit"
      @close-modal="emit('closeModal')"
    />

    <ProjectName />

    <ProjectArchive
      v-if="props.isEdit"
      :is-archive="props.isArchive"
      @close-project-modal="emit('closeModal')"
    />

    <ProjectModuleList />

    <ProjectCountry
      :is-edit="props.isEdit"
    />

    <ProjectControl
      :is-edit="props.isEdit"
      @update-project-list="emit('updateProjectList')"
      @close-modal="emit('closeModal')"
    />
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/vars.scss';
@import '@/assets/styles/classes.scss';

.modal-create-project {
  position: fixed;

  z-index: 2001;

  right: 0;
  top: 0;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  justify-content: space-between;
  width: 555px;
  height: 100%;
  padding: 30px;
  background: #FFFFFF;
  box-shadow: 0 4px 10px 0 #00000024;
  gap: 30px;
}

</style>