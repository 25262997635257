<template>
  <div
    v-if="store.confirmExitModalIsActive"
    class="modal-exit"
  >
    <div class="modal-container">
      <div class="modal-container-header">
        <SvgIcon
          width="24px"
          height="24px"
          name="x"
          @click="store.confirmExitModalIsActive = false"
        />
      </div>
      <div class="modal-container-body">
        {{ t('logout_modal.title') }}
      </div>
      <div class="modal-container-footer">
        <GlobusButtonPrimary
          class="button"
          @click="exit"
        >
          <SvgIcon
            name="logout-icon"
            width="15px"
            height="15px"
          />
          {{ t('logout_modal.button.accept') }}
        </GlobusButtonPrimary>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from '@/store';
import GlobusButtonPrimary from '@/components/common/GlobusInput/GlobusButtonPrimary.vue';
import { exit } from '@/composables/functionExit';
import SvgIcon from "@/components/common/svgButton.vue";
const store = useStore()
import { t } from '@/controllerLK/GlobusLocalization'
</script>

<style lang="scss" scoped>
@import '@/assets/styles/styles.scss';

.modal-exit {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;

  .modal-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 20px 20px 20px;
    border-radius: 20px;
    background: #FFFFFF;
    width: 300px;
    height: 240px;

    &-header {
      display: flex;
      justify-content: flex-end;

      svg {
        cursor: pointer;
      }
    }

    &-body {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      font-weight: 400;
      color: #1F9AFF;
    }

    &-footer {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      .button {
        width: 200px;
      }
    }
  }
}

</style>