<script setup>
import {defineEmits, defineProps} from "vue";
import {useProjects} from "@/store/projects";
import {useUser} from "@/store/user";
import {useStore} from "@/store";

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  projectName: {
    type: String,
    default: ''
  },
  projectId: {
    type: Number,
    default: null
  }
})

const emit = defineEmits(['closeModal', 'closeProjectModal'])

const projectStore = useProjects()
const userStore = useUser()

const deleteProject = () => {

  const store = useStore()

  if (props.projectId === userStore.user.activeProject) {
    store.createNotify('Ошибка', 'Нельзя удалить активный проект.', '')
    emit('closeModal')
    return false
  }

  projectStore.deleteProject(props.projectId)
  emit('closeModal')
  emit('closeProjectModal')
}
</script>

<template>
  <div
    v-if="props.show"
    class="modal-wrapper"
  >
    <div class="modal-delete-project">
      <div class="modal-delete-project-header">
        Подвердите действие
      </div>
      <div class="modal-delete-project-body">
        Вы действительно хотите удалить проект: {{ projectName }} ?
      </div>
      <div class="modal-delete-project-footer">
        <div
          class="modal-delete-project-footer__button modal-delete-project-footer__close"
          @click="emit('closeModal')"
        >
          Отмена
        </div>
        <div
          class="modal-delete-project-footer__button modal-delete-project-footer__delete"
          @click="deleteProject()"
        >
          Удалить
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/vars.scss';

.modal-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 300px;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 0 0 50vmax rgba(0, 0, 0, .5);
  z-index: 2000;

  .modal-delete-project {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &-header {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 500
    }

    &-body {
      word-break: break-word;
      overflow-y: hidden;
      line-height: 20px;
      background: $background-color;
      padding: 30px 20px 30px 20px;
      border-radius: 8px;
    }

    &-footer {
      display: flex;
      justify-content: center;
      gap: 10px;
      &__button {
        width: 100%;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        cursor: pointer;
        height: 40px;

      }

      &__close {
        color: $globe-active-color;
        background-color: $background-color;
      }

      &__close:hover {
        background-color: #EEF1FF;
        color: #1F9AFF;
        font-weight: 500;
      }

      &__delete {
        background-color: #1A64D4;
      }

      &__delete:hover {
        background-color: #1F9AFF;
      }
    }
  }

}
</style>