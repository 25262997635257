<template>
  <div
    id="project-wrapper"
    :class="{'project-wrapper': router.currentRoute.fullPath !== '/login',
             'title-login-box': router.currentRoute.fullPath === '/login'}"
  >
    <div
      class="project-header"
      :class="{ link: router.currentRoute.fullPath !== '/login' }"
      @click="logoHandleClick()"
    >
      <div
        id="header-module-name"
        class="project-header__title"
      >
        {{ Store.activeModule.name }}
      </div>
      <SvgButton
        :key="Store.activeModule.dataName"
        class="project-header__logo"
        height="24px"
        width="24px"
        :name="Store.activeModule.dataName"
      />
    </div>

    <div
      v-if="Store.activeModule.dataName === 'module_globus' && router.currentRoute.fullPath !== '/login' && Store.routing?.length"
      class="routing"
    >
      <div
        v-for="(link, index) of Store.routing"
        :key="index"
        class="routing-link"
        :class="{ noactive: link.text_ru.toLowerCase() !== 'новости' && link.text_ru.toLowerCase() !== 'пульс' && link.text_ru.toLowerCase() !== 'аналитика' && isProd }"
      >
        <RouterItem
          v-if="!link.disabled"
          :link="link"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SvgButton from '@/components/common/svgButton.vue';
import { useStore } from '@/store'
import router from '@/router/index'
import { goMainPage } from '@/composables/ModuleCommunication';
import RouterItem from '@/components/common/header/RouterItem.vue'

export default {
  components: { SvgButton, RouterItem },
  data() {
    return {
      Store: useStore(),
      router: router,
      locationHref: window.location.href,
      isProd: false
    }
  },
  created() {
    this.isProd = !this.locationHref.includes(':80') && !this.locationHref.includes(':7777') && process.env.NODE_ENV === 'production'
  },
  methods: {
    logoHandleClick() {
      if (this.router.currentRoute.fullPath !== '/login') {
        goMainPage()
      }
    }
  }
}
</script>

<style
  lang="scss"
  scoped
>
@import '@/style/styles.scss';

.project-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
  height: 50px;
  background: #fff;
  border-radius: 30px;
  padding: 5px 20px 5px 20px;
  box-shadow: 0 0 33px 0 #7090B029;
  user-select: none;
}

.project-header {
  display: flex;
  cursor: pointer;
  gap: 6px;
}

.project-header__title {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 2.4px;
}

.routing {
  display: flex;

  .routing-link {
    &.noactive {
      pointer-events: none;
    }
  }
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;

  &.link {
    cursor: pointer;
  }
}

.title-login-box {
  display: flex;
  align-items: center;
  justify-content:center;
}
</style>