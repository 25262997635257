<script setup>
import LKProjectWindowSearch from "@/pages/LKPage/LKWindow/LKProjectWindow/LKProjectWindowSearch.vue";
import {onMounted, ref, defineProps, computed} from "vue";
import {useProjects} from "@/store/projects";
import LKProjectModal from "@/pages/LKPage/LKWindow/LKProjectWindow/LKProjectModal.vue";
import LKDeleteProjectModal from "@/pages/LKPage/LKWindow/LKProjectWindow/LKDeleteProjectModal.vue";
import LKProjectCardNew from "@/pages/LKPage/LKWindow/LKProjectWindow/LKProjectCardNew.vue";
import LKProjectCard from "@/pages/LKPage/LKWindow/LKProjectWindow/LKProjectCard.vue";
import LKProjectWindowPaginator from "@/pages/LKPage/LKWindow/LKProjectWindow/LKProjectWindowPaginator.vue";

import { t } from '@/controllerLK/GlobusLocalization'

const props = defineProps({
  isArchive: {
    type: Boolean,
    default: false
  }
})

onMounted(async () => {
  await projectStore.getProjects()
  updateProjectList()
})

const projectStore = useProjects()
const projectList = ref([])
const isEdit = ref(false)
const projectSearchString = ref('')

const updateProjectList = () => {
  if (props.isArchive) {
    projectList.value = projectStore.projects.filter((project) => project.isArchive === true)
  } else {
    projectList.value = projectStore.projects.filter((project) => project.isArchive === false)
  }
}

/* Пагинатор **/
const activePage = ref(1)

/* Получение списка проектов на текущию страницу **/
const getPageItems = computed(() => {
  let projects = projectStore.projects.filter((project) => project.isArchive === props.isArchive)
  if (projectSearchString.value.length > 0) {
    return projects.filter((project) => {
      return project.name.toLowerCase().includes(projectSearchString.value.toLowerCase())
    })
  } else {
    return projects
  }
})


/* Модальные окна. CRUD **/
const selectedProject = ref(null)

const isShowProjectModal = ref(false)
const projectModalToggle = (project, show, edit = false) => {
  selectedProject.value = project
  isShowProjectModal.value = show
  isEdit.value = edit
}

const isShowDeleteProjectModal = ref(false)
const deleteProjectModalToggle = (project, show) => {
  selectedProject.value = project
  isShowDeleteProjectModal.value = show
}

</script>

<template>
  <div class="window-wrapper">
    <div class="header">
      <div class="header__title">
        {{ t('lk_project_window.title') }}
      </div>
      <div class="header__search">
        <LKProjectWindowSearch
          v-model="projectSearchString"
        />
      </div>
    </div>

    <div class="body">
      <LKProjectCardNew
        v-if="!isArchive"
        @click="projectModalToggle(null, true, false)"
      />

      <LKProjectCard
        v-for="project in getPageItems"
        :key="project"
        :project="project"
        :is-archive="props.isArchive"
        @edit-project="val => projectModalToggle(val, true, true)"
        @delete-project="val => deleteProjectModalToggle(val, true)"
      />
    </div>
  </div>

  <LKProjectModal
    :show="isShowProjectModal"
    :is-edit="isEdit"
    :is-archive="isArchive"
    :project="selectedProject"
    @close-modal="projectModalToggle(null, false, false)"
    @update-project-list="updateProjectList()"
  />

  <LKDeleteProjectModal
    :project-id="selectedProject?.id"
    :project-name="selectedProject?.name"
    :show="isShowDeleteProjectModal"
    @close-modal="deleteProjectModalToggle(null, false)"
  />
</template>

<style scoped lang="scss">
@import '@/assets/styles/vars';

.window-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 33px;

  height: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    gap: 5px;

    &__title {
      font-size: 28px;
      font-weight: 400;
      text-wrap: nowrap;
    }

    &__search {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    padding-bottom: 30px;
  }

  .footer {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
  }
}

</style>