<template>
  <div class="login-view">
    <form>
      <div class="login-box text-start">
        <div
          class="project-header"
        >
          <div
            id="header-module-name"
            class="project-header__title"
          >
            {{ t('project_header_logo.' + Store.activeModule.dataName) }}
          </div>
          <SvgButton
            :key="Store.activeModule.dataName"
            class="project-header__logo"
            height="24px"
            width="24px"
            :name="Store.activeModule.dataName"
          />
        </div>
        <div class="input-cont">
          <p class="log-desc">
            Логин
          </p>
          <input
            v-model="login"
            class="log-input"
            type="text"
          >
        </div>
        <div
          class="input-cont"
          style="margin-top: 5%;"
        >
          <p class="log-desc">
            Пароль
          </p>
          <div style="display: flex;position: relative;">
            <input
              v-model="password"
              autocomplete="true"
              class="log-input"
              :type="showPass ? 'text' : 'password'"
            >
            <!-- <img @click="showPass = !showPass" class="vispass-icon" :src="require('@/assets/personal-area/vispass.svg')" alt=""> -->
            <SvgButton
              v-if="!showPass"
              style="position: absolute;top:21%;right: 0%;cursor: pointer;"
              height="60%"
              width="10%"
              name="vispass"
              @action="showPass = !showPass"
            />
            <SvgButton
              v-else
              style="position: absolute;top:21%;right: 0%;cursor: pointer;"
              height="60%"
              width="10%"
              name="vispasstrue"
              @action="showPass = !showPass"
            />
          </div>
        </div>
        <div
          class="login-btn"
          @click="sendLoPas()"
        >
          Войти
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {LogIn} from '@/composables/auth'
import SvgButton from '@/components/common/svgButton.vue'
import {changeShow, changeLoadApp, setProject} from '@/composables/ModuleCommunication'
import {useStore} from "@/store/index.js"
import { useProjects } from '@/store/projects';
import { useUser } from '@/store/user';
import { exit } from '@/composables/functionExit';
import router from '@/router/index';
import {t} from "@/controllerLK/GlobusLocalization";

export default {
  components: {
    SvgButton
  },
  data(){
    return {
      Store: useStore(),
      storeProjects: useProjects(),
      login: '',
      password: '',
      showPass: false,
      userStore: useUser(),
    }
  },
  mounted(){
    this.login = ''
  },
  methods: {
    t,
    async sendLoPas(){
      this.Store.loader = true
      try{
        if(this.login && this.password){
          let loginData = await LogIn({
            username: this.login,
            password: this.password
          })
          this.login = ''
          this.password = ''
          if(loginData){
            await this.userStore.getUser()


            if (this.userStore.user.language.toLowerCase() !== localStorage.getItem('globusLanguage')) {
              localStorage.setItem('globusLanguage', this.userStore.user.language.toLowerCase())
              window.location.reload()
            }

            router.push(this.Store.mainHrefApp)
            if(!this.userStore.user.modules.find(item => item.moduleId === this.Store.activeModule.dataName) && this.Store.activeModule.dataName !== 'module_proxy' ){
              this.Store.showType = 'login'
              exit()
              console.log('Нет доступа к модулю', 'У данного пользователя нет доступа к модулю, обратитесь к администратору', this.Store.activeModule.dataName)
              this.Store.createNotify('Нет доступа к модулю', 'У данного пользователя нет доступа к модулю, обратитесь к администратору', '')
              this.Store.loader = false
              return
            }
            if(this.userStore.user.activeProject){
              const project = await this.storeProjects.getProject(this.userStore.user.activeProject)
              if(project){
                this.storeProjects.activeProject = project
                this.Store.showType = 'close'
                setProject(project)
                changeLoadApp(true)
                changeShow(true)
              } else {
                changeLoadApp(false)
                changeShow(false)
                this.Store.showType = 'open'
              }
            } else {
              changeLoadApp(false)
              changeShow(false)
              this.Store.showType = 'open'
            }
          } else {
            this.Store.createNotify('Неверный логин/пароль', 'Если ошибка повторится и вы уверены что вводимые данные верны, обратитесь к администратору', '')
          }
        }
      } catch(e) {
        console.log(e)
      }
      this.Store.loader = false
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/styles.scss';
.project-header {
  display: flex;
  justify-content: center;
  cursor: pointer;
  gap: 6px;

  &__title {
    font-family: Roboto,serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 2.4px;
  }
}

.vispass-icon{
  height: 2vh;
  width: 2vh;
  position: absolute;
  top:1vh;
  left: 92%;
  cursor: pointer;
}
.login-view {
  background-color: #09518C;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  z-index: 1000001;
}
.login-btn{
  background-color: rgba(26, 100, 212, 1);
  border-radius: $border-radius-mini;
  color: white;
  height: 40px;
  margin-top: 30px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.login-btn:hover{
  background-color:rgba(22, 88, 188, 1);
}
.login-box {
  width: 660px;
  height: 530px;
  background: white;
  border-radius:$border-radius;
  box-sizing: border-box;
  padding: 110px 90px 0 90px;
}
.input-cont{
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.log-desc{
  margin: 10px 0 5px 0;
  display: flex;
  height: 7%;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
}
.log-input{
  display: block;
  width:100%;
  padding: 0.375rem 0.75rem;
  font-size: 20px;
  font-weight: 400;
  line-height: 18px;
  color: #212529;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color:#fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius:  0.375rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  box-sizing: border-box;
}




</style>