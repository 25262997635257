export const buttons = [
    {
        name:'settings' ,
        text: 'Профиль и настройки',
    },
    {
        name: 'projects' ,
        text: 'Проекты' ,
    },
    {
        name: 'services' ,
        text: 'Сервисы' ,
    },
    {
        name: 'archive' ,
        text: 'Архив' ,
    },
    {
        name:'exit' ,
        text: 'Выход' ,
    }
]