<script setup>
import GlobusButtonPrimary from "@/components/common/GlobusInput/GlobusButtonPrimary.vue";
import GlobusButtonSecondary from "@/components/common/GlobusInput/GlobusButtonSecondary.vue";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  cancelButtonText: {
    type: String,
    required: false,
    default: () => 'Отмена'
  },
  confirmButtonText: {
    type: String,
    required: false,
    default: () => 'Сохранить'
  }
})

const emit = defineEmits(['confirm', 'cancel'])

</script>

<template>
  <div class="globus-modal-wrapper">
    <div class="globus-modal">
      <div class="globus-modal__title">
        {{ props.title }}
      </div>
      <div class="globus-modal__body">
        <slot />
      </div>
      <div class="globus-modal-footer">
        <GlobusButtonSecondary @click="emit('cancel')">
          {{ props.cancelButtonText }}
        </GlobusButtonSecondary>
        <div class="globus-modal-footer__confirm">
          <GlobusButtonPrimary @click="emit('confirm')">
            {{ props.confirmButtonText }}
          </GlobusButtonPrimary>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/vars.scss';

.globus-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);

  .globus-modal {
    display: flex;
    flex-direction: column;
    gap: 14px;
    border-radius: 8px;
    box-shadow: 1px 2px 10px 0 #131E2726;
    background: $globus-background-secondary-color;
    padding: 14px;

    &__title {
      color: $globus-background-secondary-text-color;
      font-size: 14px;
      font-weight: 500;
    }

    &__body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      gap: 5px;
      flex-wrap: wrap;
      border-radius: 10px;
      color: $globus-background-primary-text-color;
      font-size: 14px;
      font-weight: 400;
      background: $globus-background-primary-color;
      padding: 30px 20px 30px 20px;
    }

    &-footer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 14px;
    }
  }

}

</style>