<template>
  <div class="globus-header">
    <LogoutDialog />
    <HeaderProject />
    <HeaderMenu v-if="useHeaderMenu" />
  </div>
</template>

<script setup>
import HeaderProject from "@/components/GlobusHeader/HeaderProject.vue";
import LogoutDialog from "@/components/GlobusHeader/LogoutDialog.vue";
import HeaderMenu from "@/components/GlobusHeader/HeaderMenu.vue";
import {computed} from "vue";
import {modules} from "@/composables/constans/modules";
import {useStore} from "@/store";

const store = useStore()

const useHeaderMenu = computed(() => {
  return modules.filter((module) => {
    return module.dataName === store.activeModule.dataName
  })[0]?.meta?.useMenu
})

</script>

<style lang="scss" scoped>
@import '@/assets/styles/styles.scss';

.globus-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  padding: 20px;
}
</style>