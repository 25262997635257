<script setup>
import SvgButton from "@/components/common/svgButton.vue";
import {useStore} from "@/store";
import {goMainPage} from "@/composables/ModuleCommunication";
import {useRouter} from "vue-router";
import { t } from '@/controllerLK/GlobusLocalization'

const router = useRouter()
const Store = useStore()

const logoHandleClick = () => {
  if (router.currentRoute.fullPath !== '/login') {
    goMainPage()
  }
}
</script>

<template>
  <div
    class="project-header"
    :class="{ link: router.currentRoute.fullPath !== '/login' }"
    @click="logoHandleClick()"
  >
    <div
      id="header-module-name"
      class="project-header__title"
    >
      {{ t('project_header_logo.' + Store.activeModule.dataName) }}
    </div>
    <SvgButton
      :key="Store.activeModule.dataName"
      class="project-header__logo"
      height="24px"
      width="24px"
      :name="Store.activeModule.dataName"
    />
  </div>
</template>

<style scoped lang="scss">
.project-header {
  display: flex;
  cursor: pointer;
  gap: 6px;

  &__title {
    font-family: Roboto,serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 2.4px;
  }
}
</style>