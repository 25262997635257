import { defineStore } from "pinia";

export const useStore = defineStore({
    id: 'store',
    state: () => ({
        loader: false,
        notifications: [],
        showType: 'login', // login close open
        activeModule: { name: 'Глобус', dataName: 'module_globus', img: '' },
        confirmExitModalIsActive: false,
        routing: [],
        mainHrefApp: '',
        showMode: 'projects',
        language: {
            code: localStorage.getItem('globusLanguage') ? localStorage.getItem('globusLanguage') : localStorage.setItem('globusLanguage', 'ru')
        },

    }),
    actions: {
        createNotify(head, mess, type) {
            this.notifications.push({
                header: head,
                message: mess,
                color: type === 'succ' ? 'green' : 'red',
                lifeTime: type === 'succ' ? 5000 : 5000
            })
        },
    }
});
