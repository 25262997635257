<script setup>

import { defineModel } from 'vue'
import SvgIcon from "@/components/common/svgButton.vue";

const model = defineModel()



</script>

<template>
  <div class="search-input-wrapper">
    <input
      v-model="model"
      type="text"
      placeholder="Поиск по проектам"
    >
    <div class="search-icon">
      <SvgIcon
        name="search"
        width="15px"
        height="15px"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/vars.scss';

.search-input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

input {

  min-width: 85px;
  width: 180px;

  height: 36px;
  border-radius: 8px 0 0 8px;
  padding: 10px;
  gap: 10px;
  border-top: 1px solid $grey-light;
  border-left: 1px solid $grey-light;
  border-bottom: 1px solid $grey-light;
  border-right: none;
  transition: ease all .5s;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 750px) {
    width: 85px;
  }

}

.search-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 36px;
  width: 26px;
  padding-right: 10px;
  border-radius: 0 8px 8px 0;

  border-top: 1px solid $grey-light;
  border-right: 1px solid $grey-light;
  border-bottom: 1px solid $grey-light;
  border-left: none;
}
</style>