<script setup>
import SvgIcon from "@/components/common/svgButton.vue";
import {ref} from "vue";
import BlockView from "@/components/common/BlockView.vue";

const showDropdown = ref(false)

const props = defineProps({
  optionList: {
    type: Array,
    required: true,
    default: () => { return [] }
  },
})

const selectedItem = ref(props.optionList[0])

</script>

<template>
  <div class="dropdown-wrapper">
    <div
      class="dropdown__selected"
      @click="showDropdown = !showDropdown"
    >
      {{ selectedItem?.value }}
      <SvgIcon
        width="15px"
        height="15px"
        name="chevron-down"
      />
    </div>
    <div
      v-if="showDropdown"
      class="dropdown-list"
    >
      <div
        v-for="option in optionList"
        :key="option"
        class="dropdown-list__item"
        @click="selectedItem = option; showDropdown = false"
      >
        <SvgIcon
          v-if="option.icon"
          :name="option.icon"
          width="16px"
          height="16px"
        />
        {{ option.value }}
      </div>
    </div>
  </div>

  <BlockView
    v-if="showDropdown"
    @click="showDropdown = false"
  />
</template>

<style scoped lang="scss">
@import '@/assets/styles/vars.scss';


.dropdown-wrapper {
  min-height: 20px;
  min-width: 80px;
  position: relative;

  .dropdown__selected {
    width: 180px;
    text-align: end;
  }

  .dropdown-list {
    background: #FFFFFF;
    box-shadow: 1px 2px 10px 0 #131E2726;
    position: absolute;
    padding: 6px;
    border-radius: 8px;
    z-index: 2000;

    &__item {
      color: black;
      min-width: 80px;
      min-height: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 6px 14px 6px 14px;
      border-radius: 8px;
      gap: 4px;
    }

    &__item:hover {
      background: $hover-active-color;
    }
  }
}

</style>