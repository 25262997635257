<script setup>

import SvgIcon from "@/components/common/svgButton.vue";
import {inject, provide, ref} from "vue";
import GlobusDialog from "@/components/common/GlobusDialog.vue";
import GlobusComboBox from "@/components/common/GlobusInput/GlobusComboBox.vue";
import {useProjects} from "@/store/projects";

const projectStore = useProjects()

const props = defineProps({
  isEdit: {
    type: Boolean,
    default: false
  }
})
const createProjectFormData = inject('createProjectFormData')

const isShowRegionOption = ref(false)

const uploadMap = () => {
  isShowRegionOption.value = false
  isShowMapUploadDialog.value = true
}
const isShowMapUploadDialog = ref(false)

const regionSearch = () => {
  isShowRegionSearch.value = true
  isShowRegionOption.value = false
}

const isShowRegionSearch = ref(false)

const addRegionOption = ref([
  {
    name: 'Поиск региона',
    icon: null,
    action: regionSearch
  },
  {
    name: 'Своя карта',
    icon: 'upload',
    action: uploadMap
  }
])

const selectedRegionList = inject("selectedRegionList")

const deleteRegionFromList = (item) => {
  console.log(item)
  if (props.isEdit) {
    projectStore.deleteProjectMap(createProjectFormData.value.id, item.name)
    selectedRegionList.value.splice(selectedRegionList.value.indexOf(item), 1)
  } else {
    selectedRegionList.value.splice(selectedRegionList.value.indexOf(item), 1)
  }
}

</script>

<template>
  <div class="country-wrapper">
    <div class="header">
      <div class="header__title">
        Доступные регионы
      </div>

      <div
        v-if="!isShowRegionSearch"
        class="header__button"
        :class="{'header__buton_clicked': isShowRegionOption}"
        @click.self="isShowRegionOption = true"
      >
        Добавить
        <SvgIcon
          name="plus_bold"
          width="14px"
          height="14px"
          color="#FFF"
          @click.self="isShowRegionOption = true"
        />

        <div
          v-if="isShowRegionOption"
          class="dropdown"
        >
          <div
            v-for="item in addRegionOption"
            :key="item"
            class="dropdown__item"
            @click="item.action"
          >
            <SvgIcon
              :name="item.icon"
              width="16px"
              height="16px"
            />
            {{ item.name }}
          </div>
        </div>
      </div>

      <div v-else>
        <GlobusComboBox
          :is-edit="props.isEdit"
          @close-region-search="isShowRegionSearch = false"
        />
      </div>
    </div>

    <div class="body">
      <div
        v-for="item in selectedRegionList"
        :key="item"
        class="selected-region"
      >
        {{ item.name }}
        <SvgIcon
          class="trash"
          name="trash"
          width="16px"
          height="16px"
          @click="deleteRegionFromList(item)"
        />
      </div>
    </div>
  </div>


  <GlobusDialog
    v-if="isShowMapUploadDialog"
    title="Загрузите файл со своей картой"
    @confirm="isShowMapUploadDialog = false"
    @cancel="isShowMapUploadDialog = false"
  >
    <SvgIcon
      name="upload"
      width="16px"
      height="16px"
    />
    GEO JSON
  </GlobusDialog>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/vars.scss';

.country-wrapper {
  border: 1px solid #EEEEEE;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  border-radius: 8px;
  min-height: 200px;
}

.body {
  .selected-region {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    color: $globus-button-secondary-text-color;
    font-weight: 400;
    font-size: 14px;
    padding: 10px 14px 10px 14px;

    .trash {
      cursor: pointer;
    }

    &:hover {
      background: $globus-background-primary-color;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  height: 40px;

  &__title {
    font-size: 16px;
    font-weight: 500;
    color: $text-01;
  }

  &__button {
    position: relative;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 6px 15px 6px 15px;
    cursor: pointer;
    user-select: none;
    color: #FFFFFF;
    border-radius: 8px;
    background: $button-active-color;
    gap: 6px;

    &_clicked {
      background: $button-clicked-color;
    }

    .dropdown {
      display: flex;
      flex-direction: column;
      gap: 4px;
      position: absolute;
      width: 160px;
      top: 50px;
      right: 0;
      padding: 6px;
      background: #FFFFFF;
      box-shadow: 1px 2px 10px 0 #131E2726;
      border-radius: 8px;

      &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        padding: 6px 14px 6px 14px;
        color: $text-01;
        font-size: 14px;
        font-weight: 400;
        gap: 4px;

        &:hover {
          background: $hover-active-color;
        }
      }

    }

  }
}

</style>
