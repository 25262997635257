<script setup>
import { locales, useGlobusLocal } from '@/controllerLK/GlobusLocalization'
import { ref } from "vue";
import {useUser} from "@/store/user";
import BlockView from "@/components/common/BlockView.vue";

const userStore = useUser()

const toogleLangVisibile = () => {
  openLangs.value = !openLangs.value
}

const changeLang = async (lang) => {
  if (lang === 'ru' || lang === 'en') {
    await userStore.changeUserLanguage(lang).then((res) => {
      if (res.data) {
        useGlobusLocal().setLocale(lang)
        window.location.reload()
      }
    })
  }
}

const langs = locales
const openLangs = ref(false)

</script>

<template>
  <div
    class="centried header-langs"
  >
    <div
      class="header-langs-title"
      @click="toogleLangVisibile"
    >
      {{ userStore.user.language }}
    </div>
    <div
      v-if="openLangs"
      class="header-dropdown _langs"
    >
      <div
        v-for="(lang, index) of langs"
        :key="index"
        :class="{ active: lang.code === userStore.user.language.toLowerCase() }"
        class="lang-item"
        @click="toogleLangVisibile(); changeLang(lang.code)"
      >
        {{ lang.code.toUpperCase() }}
      </div>
    </div>
  </div>
  <BlockView
    v-if="openLangs"
    @click="openLangs = false"
  />
</template>

<style scoped lang="scss">
@import '@/assets/styles/styles.scss';

.header-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.header-background {
  position: fixed;
  z-index: 1999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.header-dropdown {
  position: absolute;
  z-index: 2000;
  top: 6vh;
  right: 0;
  box-sizing: border-box;
  min-width: 138px;
  min-height: 76px;
  padding: 6px;
  border-radius: 8px;
  background-color: white;
  box-shadow: $box-shadow;

  &._langs {
    top: 6vh;
    right: auto;
    left: 50%;
    width: 100%;
    min-width: 6vh;
    padding: 1vh;
    transform: translate(-50%, 0);
    border-radius: 8px;
    box-shadow: 2px 4px 20px 0px rgba(0, 32, 57, 0.15);
  }
}



.header-langs {
  cursor: pointer;
  position: relative;
}

.header-langs-title {
  font-family: sans-serif;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 36px;
  height: 44px;
  cursor: pointer;
  color: black;
  border-radius: 50%;
}

.lang-item {
  font-size: 1.6vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4vh;
  height: 4vh;
  cursor: pointer;
  transition: .2s;
  border: 1px solid transparent;
  border-radius: 0.8vh;

  &.active {
    cursor: default;
    color: $globe-active-color;
    border-color: $globe-active-color;
  }

  &:hover {
    &:not(.active) {
      background-color: #F4FAFF;
    }
  }

  &:active {
    &:not(.active) {
      background-color: #F4FAFF;
    }
  }
}
</style>