<script setup>
import SvgIcon from "@/components/common/svgButton.vue";
import {inject, onMounted, ref} from "vue";
import {useUser} from "@/store/user";
import GlobusDropdown from "@/components/common/GlobusInputCommon/GlobusDropdown.vue";
import GlobusCheckbox from "@/components/common/GlobusInputCommon/GlobusCheckbox.vue";
import BlockView from "@/components/common/BlockView.vue";

const userStore = useUser()
const createProjectFormData = inject('createProjectFormData')

const props = {
  isEdit: {
    type: Boolean,
    default: false,
  }
}

onMounted(() => {
  userStore.getOrganizationMembers()

  if (props.isEdit) {
    for (let user of createProjectFormData.value.users) {
      const foundedUser = userStore.organizationMembers.find((orgMember) => {
        return orgMember.userId === user
      })

      if (foundedUser) {
        addedUserList.value.push(foundedUser)
      }
    }
  }
})

defineProps({
  showUserList: {
    type: Boolean,
    default: false
  },
  isEdit: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['closeModal'])

const accessList = [
  {
    value: 'Чтение',
    icon: 'eye-black'
  },
  {
    value: 'Редактирование',
    icon: 'pen-black'
  }
]

const addedUserList = ref([])

const toggleUser = (value, user) => {
  if (value) {
    addedUserList.value.push(user)
  } else {
    const index = addedUserList.value.findIndex((item) => item === user)
    addedUserList.value.splice(index, 1)
  }
}

const checkUserToggle = (userId) => {
  return addedUserList.value.some((item) => item.userId === userId)
}

const saveUserList = () => {
  for (let user of addedUserList.value) {
    createProjectFormData.value.users.push(user.userId)
  }
  createProjectFormData.value.users = [...new Set(createProjectFormData.value.users)]
  emit('closeModal')
}


</script>

<template>
  <div
    v-if="showUserList"
    class="user-list-modal"
  >
    <div class="header">
      <div class="header__title">
        Настройки доступа
      </div>
      <div class="header__close">
        <SvgIcon
          height="24px"
          name="x"
          width="24px"
          @click="emit('closeModal')"
        />
      </div>
    </div>

    <div
      v-if="userStore.organizationMembers.length > 0"
      class="body"
    >
      <div
        v-for="user in userStore.organizationMembers"
        :key="user"
        class="user"
      >
        <div class="user__name">
          <GlobusCheckbox
            :check-box-value="checkUserToggle(user.userId)"
            @on-change="value => { toggleUser(value, user) }"
          />
          {{ user.lastName || user.firstName ?
            user.lastName + ' ' + user.firstName + ' ' + user?.middleName : user.userId }}
        </div>
        <div
          v-if="userStore.user.userId === user.userId"
          class="user__title"
        >
          <SvgIcon
            height="14px"
            name="user-check"
            width="14px"
          />
          Владелец
        </div>
        <div
          v-else
          class="user__access"
        >
          <GlobusDropdown
            v-if="checkUserToggle(user.userId)"
            :option-list="accessList"
          />
        </div>
      </div>
    </div>

    <div
      v-else
      class="body-no-member"
    >
      В вашей организации пока нет участников.
    </div>

    <div
      v-if="userStore.organizationMembers.length > 0"
      class="footer"
    >
      <div
        class="footer__save"
        @click="saveUserList()"
      >
        Сохранить изменения
      </div>
    </div>
  </div>
  <BlockView
    v-if="showUserList"
  />
</template>

<style lang="scss" scoped>
@import '@/assets/styles/vars.scss';

.user-list-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  min-width: 250px;
  max-width: 700px;

  max-height: 600px;

  padding: 20px;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: 0 0 0 100vmax rgba(0,0,0,.5);
  gap: 20px;

  z-index: 2001;

  @media (max-height: 600px) {
    max-height: 250px;
  }

  .header {
    display: flex;
    justify-content: space-between;

    &__title {
      font-size: 14px;
      font-weight: 600;
    }

    &__close {
      cursor: pointer;
      user-select: none;
    }
  }

  .body-no-member {
    height: 100px;
    width: 215px;
    background: $background-color;
    padding: 30px 20px 30px 20px;
    color: #373D3F;
  }

  .body {
    min-width: 500px;
    overflow: auto;
    height: 100%;
    padding: 10px;
    border: 1px solid $globe-passive-color;
    border-radius: 8px;

    .user {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px;
      border-radius: 8px;
      gap: 10px;

      &__name {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
      }

      &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $globe-text-grey;
        gap: 5px;
      }

      &__access {
        color: $globe-text-grey;
      }
    }

    .user:hover {
      cursor: pointer;
      user-select: none;
      background: $hover-active-color;
    }
  }

  .footer {
    &__save {
      padding: 6.4px 15px 6.4px 15px;
      cursor: pointer;
      user-select: none;
      color: $button-active-text-color;
      border-radius: 8px;
      background: $button-active-color;
    }
  }
}
</style>