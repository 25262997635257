<script setup>
import HeaderProjectMenuBlock from "@/components/GlobusHeader/HeaderMenu/HeaderProjectMenu/HeaderProjectMenuBlock.vue";
import {ref} from "vue";
import {useProjects} from "@/store/projects";
import {goLkPage, goMainPage} from "@/composables/ModuleCommunication";
import {useStore} from "@/store";
import {useRouter} from "vue-router";
import SvgIcon from "@/components/common/svgButton.vue";

import { t } from '@/controllerLK/GlobusLocalization'

const props = defineProps(['openParam', 'openNav', 'openLangs', 'popupProjectsOn'])
const emit = defineEmits(['togglePopupVisible'])
const KEY = ref(1)
const projectStore = useProjects()

const Store = useStore()


const openProjects = () => {
  goLkPage()
  Store.showMode = "projects"
}

const router = useRouter()


</script>

<template>
  <div
    v-if="router.currentRoute.value.fullPath !== '/lk'"
    :class="{active: popupProjectsOn}"
    class="header-project-name"
    @click="emit('togglePopupVisible')"
  >
    <span
      v-if="projectStore.activeProject"
      class="header-project-name__active"
    >
      {{ t('header_project_menu.label.project') + ': ' + projectStore.activeProject.name }}
    </span>
    <span v-else>{{ t('header_project_menu.label.choose_project') }}</span>
    <div
      v-if="popupProjectsOn"
      class="header-project-name__popup"
    >
      <HeaderProjectMenuBlock
        :key="KEY"
        @refresh="KEY++"
      />
      <button @click="openProjects">
        {{ t('header_project_menu.button.open') }}
      </button>
    </div>
  </div>
  <div
    v-else
    class="main-page-button"
    @click="goMainPage()"
  >
    <SvgIcon
      name="arrow-left"
      width="16px"
      height="16px"
    />
    {{ t('header_project_menu.button.back') }}
  </div>
</template>

<style scoped lang="scss">

@import "@/assets/styles/vars.scss";

.main-page-button {
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 5px;
  height: 38px;
  padding: 6px 14px 6px 14px;
  border-radius: 22px;
  background: #fff;
  cursor: pointer;
  user-select: none;
  color: $grey-dark;
  font-size: 14px;
  font-weight: 400;
}


.header-project-name {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  display: flex;

  min-width: 120px;
  max-width: 160px;

  align-items: center;
  height: 30px;
  padding: 8px 14px;
  cursor: pointer;
  text-align: left;

  border-radius: 22px;
  background: #fff;

  &__active {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  &.active {
    background: #1A64D433;
  }

  &__popup {
    position: absolute;
    z-index: 9999;
    top: 5vh;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 255px;
    height: 150px;
    min-height: 150px;
    padding: 6px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 1px 2px 10px 0 #131E2726;
    gap: 4px;

    button {
      font-size: 16px;
      line-height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 38px;
      min-height: 38px;
      cursor: pointer;
      color: #fff;
      border: none;
      border-radius: 8px;
      background: #1A64D4;
    }
  }
}
</style>