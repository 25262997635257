<script setup>

import SvgIcon from "@/components/common/svgButton.vue";
import {inject, ref} from "vue";
import {useProjects} from "@/store/projects";
import UserListModal from "@/components/LKPage/LKWindow/ProjectWindow/ProjectModal/ProjectControl/UserListModal.vue";

const props = defineProps({
  isEdit: {
    type: Boolean,
    required: true,
    default: false
  }
})
const emit = defineEmits(['closeModal', 'updateProjectList'])

const projectStore = useProjects()

const showUserList = ref(false)

const errorList = inject('errorList')
const createProjectFormData = inject('createProjectFormData')
const selectedRegionList = inject("selectedRegionList")

/* POST Запрос создания нового проекта **/
const createProject = async () => {
  errorList.value = []
  createProjectFormData.value.name = createProjectFormData.value.name.trim()
  if (createProjectFormData.value.name.length === 0) {
    errorList.value.push('noName')
  }

  // eslint-disable-next-line
  const emojiRegEx = new RegExp(/[^\x00-\x7Fа-яА-Я]/g)

  if (createProjectFormData.value.name.search(emojiRegEx) !== -1) {
    errorList.value.push('noEmoji')
  }

  if (createProjectFormData.value.modules.length === 1) {
    errorList.value.push('noModule')
  }

  if (!errorList.value.length > 0) {
    if (!props.isEdit) {
      await projectStore.createProject(createProjectFormData.value).then((res) => {
        for (let region of selectedRegionList.value) {
          console.log('creating map')
          projectStore.createMap(res.id, region.id, region.name)
        }
      })

    } else {
      await projectStore.updateProject(createProjectFormData.value)
    }

    emit('closeModal')
    emit('updateProjectList')
  }
}

</script>

<template>
  <div class="project-control">
    <div
      class="project-control__add-member"
      @click="showUserList = true"
    >
      <SvgIcon
        height="15px"
        name="access-control-blue"
        width="15px"
      />

      <span>Настройки доступа</span>

      <SvgIcon
        height="15px"
        name="lock-blue"
        width="15px"
      />
    </div>
    <div
      class="project-control__create"
      @click="createProject()"
    >
      <span v-if="!isEdit">Создать</span>
      <span v-else>Изменить</span>
    </div>
  </div>



  <UserListModal
    :show-user-list="showUserList"
    @close-modal="showUserList = false"
  />
</template>

<style lang="scss" scoped>
@import '@/assets/styles/vars.scss';

.project-control {
  display: flex;
  justify-content: space-between;
  user-select: none;

  &__add-member {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 220px;
    height: 40px;
    padding: 6px 15px 6px 15px;
    cursor: pointer;
    color: $globe-active-color;
    border-radius: 8px;
    background: $background-color;
    gap: 10px;
  }

  &__add-member:hover {
    background: $hover-active-color;
  }

  &__create {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95px;
    height: 40px;
    padding: 6px 15px 6px 15px;
    cursor: pointer;
    color: #FFFFFF;
    border-radius: 8px;
    background: $blue-dark;
  }
}
</style>