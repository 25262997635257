import {defineStore} from "pinia";
import axios from "axios";
import { setProject } from "@/composables/ModuleCommunication";
import {useStore} from "@/store/index";
import {useUser} from "@/store/user";
import { t } from '@/controllerLK/GlobusLocalization'

export const useProjects = defineStore({
    id: 'projectsStore',
    state: () => ({
        projects: [],
        activeProject: '',
        countries: [],
        foundedCountryId: '',
        foundedRegionList: []
    }),
    actions: {

        async getProject(id){
            let result = await axios.get(`/globus/api/Projects/${id}`)
            return result.data
        },
        async getProjects(){
            await axios.get(`/globus/api/Projects`).then((response) => {
                this.projects = response.data
            }).catch((error) => {
                console.log(error)
            })
        },
        async createProject(data){
            let result

            await axios.post(`/globus/api/Projects`, data).then(async (res) => {
                const mainStore = useStore()
                mainStore.createNotify('Успех', t('notification.project_created.message'), 'succ')
                await this.getProjects()
                result = res.data
            }).catch((error) => {
                console.log(error)
            })
            return result
        },
        async updateProject(data){
            await axios.patch(`/globus/api/Projects/${data.id}`, data).then(async () => {
                const mainStore = useStore()
                const userStore = useUser()
                mainStore.createNotify('Успех', t('notification.save.message'), 'succ')
                this.activeProject = await this.getProject(userStore.user.activeProject)
                await this.getProjects()

            }).catch((error) => {
                console.log(error)
            })
        },
        async deleteProject(id){
            await axios.delete(`/globus/api/Projects/${id}`).then(() => {
                const mainStore = useStore()
                mainStore.createNotify('Успех', t('notification.project_delete.message'), 'succ')
                this.getProjects()

            }).catch((error) => {
                console.log(error)
            })
        },

        async setActiveProject(id) {
            const userStore = useUser()

            await axios.patch(`/globus/api/User/profile`, {activeProject: id}).then(async (response) => {
                console.log(response)
                console.log(userStore.user.activeProject)

                this.activeProject = this.projects.find(project => project.id === id)
                userStore.user.activeProject = this.activeProject.id
                console.log(userStore.user.activeProject)

            }).catch((error) => {
                console.log(error)
            })
        },

        clearData() {
            this.projects = []
            this.activeProject = ''
            this.countrys = ''
            setProject('') // очистка юзера в приложение 
        },

        async getCountry(iso2) {
            await axios.get(`/globus/api/Country/${iso2}`).then((response) => {
                this.foundedCountryId = response.data
                return response.data
            }).catch((error) => {
                console.log(error)
                return false
            })
        },

        async getCountries(){
            await axios.get(`/globus/api/Country`).then((response) => {
                this.countries = response.data
            }).catch((error) => {
                console.log(error)
            })
        },

        async searchRegion(value) {
            let result

            await axios.get(`/globus/api/MapFile/locations/${value}`).then( (res) => {
                this.foundedRegionList = res.data
                result = res.data
            })

            return result
        },


        async createMap(projectId, locationId, mapName) {
            await axios.post(`/globus/api/MapFile/projects/${projectId}/maps/${locationId}`, {mapName: mapName})
        },

        async getProjectMapList(projectId) {
            let result
            await axios.get(`/globus/api/MapFile/projects/${projectId}/maps`).then((response) => {
                result = response.data
            })
            return result
        },

        async deleteProjectMap(projectId, mapName) {
            await axios.delete(`/globus/api/MapFile/projects/${projectId}/maps/${mapName}`)
        }
    }
});
