<script setup>

import {goLkPage, goMainPage} from "@/composables/ModuleCommunication";
import {useUser} from "@/store/user";
import {mainStorage as mainStorageImport} from "@/composables/tools/main-storage.tools";
import { t } from '@/controllerLK/GlobusLocalization'


const props = defineProps(['openParam'])
const emit = defineEmits(['toogleProfileVisible'])

const userStore = useUser()
const mainStorage = mainStorageImport

import SvgIcon from "@/components/common/svgButton.vue";
import {confirmExit} from "@/composables/functionExit";
import {useRouter} from "vue-router";
const router = useRouter()

const exit = () => {
  confirmExit()
}

</script>

<template>
  <div
    class="header-user-profile profile "
    @click="emit('toogleProfileVisible')"
  >
    <img
      v-if="userStore.user?.image"
      :src="userStore.user?.image"
      alt=""
      class="user-image"
    >
    <SvgIcon
      v-else
      class="user-image"
      name="undefined-photo"
    />
    <div
      v-if="openParam"
      class="header-dropdown"
    >
      <div
        v-if="router.currentRoute.value.fullPath === '/lk'"
        class="panel-item header-dropdown__item"
        @click="goMainPage()"
      >
        <SvgIcon
          height="15px"
          name="home"
          width="15px"
        />

        <p class="button-text">
          {{ t('user_profile.main_page') }}
        </p>
      </div>
      <div
        class="panel-item header-dropdown__item"
        to="lk"
        @click="goLkPage()"
      >
        <SvgIcon
          height="15px"
          name="user"
          width="15px"
        />

        <p class="button-text">
          {{ mainStorage.getItem('username') }}
        </p>
      </div>
      <div
        class="panel-item header-dropdown__item"
        @click="exit()"
      >
        <SvgIcon
          height="15px"
          name="exit"
          width="15px"
        />

        <p class="button-text">
          {{ t('user_profile.exit') }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

@import '@/assets/styles/styles.scss';

.header-user-profile {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}



.header-dropdown-option {
  width: 100%;
  padding: 1vh;
  cursor: pointer;
  text-wrap: nowrap;

  &:hover {
    background-color: $status-active-hover;
  }
}

.profile {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #FFFFFF;
}

.header-dropdown__item {
  display: flex;
  margin: 0;
  text-decoration: none;
  gap: 5px;
}

.user-image {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.header-dropdown-option {
  width: 100%;
  padding: 1vh;
  cursor: pointer;
  text-wrap: nowrap;

  &:hover {
    background-color: $status-active-hover;
  }
}

.header-dropdown {
  position: absolute;
  z-index: 2000;
  top: 6vh;
  right: 0;
  box-sizing: border-box;
  min-width: 138px;
  min-height: 76px;
  padding: 6px;
  border-radius: 8px;
  background-color: white;
  box-shadow: $box-shadow;

  &._langs {
    top: 6vh;
    right: auto;
    left: 50%;
    width: 100%;
    min-width: 6vh;
    padding: 1vh;
    transform: translate(-50%, 0);
    border-radius: 8px;
    box-shadow: 2px 4px 20px 0px rgba(0, 32, 57, 0.15);
  }
}
</style>