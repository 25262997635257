<template>
  <ProjectWindow v-if="store.showMode === 'projects'" />
  <ProfileWindow v-if="store.showMode === 'settings'" />
  <ServicesWindow v-if="store.showMode === 'services'" />
  <ProjectWindow
    v-if="store.showMode === 'archive'"
    :is-archive="true"
  />
</template>

<script setup>
import ProjectWindow from "@/components/LKPage/LKWindow/ProjectWindow.vue";
import ServicesWindow from "@/components/LKPage/LKWindow/ServiceWindow.vue";
import ProfileWindow from "@/components/LKPage/LKWindow/ProfileWindow.vue";

import { useStore } from '@/store';

const store = useStore()
</script>

<style scoped lang="scss">
.window-wrapper {
  display: flex;
  flex-direction: column;

  gap: 33px;

  .header {
    display: flex;
    justify-content: space-between;

    &__title {
      font-size: 28px;
      font-weight: 400;
    }

    &__search {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>