<template>
  <div class="loader-global">
    <LocalLoader />    
  </div>
</template>

<script>
import LocalLoader from "./LocalLoader.vue"
export default {
    components: {LocalLoader},
}
</script>

<style>
.loader-global{
    display: flex;
    align-items: center;
    background-color: gray;
    opacity: 0.4;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100000000000;
    justify-content: center;
}
</style>