<template>
  <div
    class="routing-item"
    :class="{active: isActive}"
    @click="routerClick()"
  >
    <img
      v-if="isActive"
      :src="getImageUrl(link.icon + '-active')"
      alt=""
    >
    <img
      v-else
      :src="getImageUrl(link.icon + '-disabled')"
      alt=""
    >
    <div class="clue">
      {{ t('router_item.' + link.id) }}
    </div>
  </div>
</template>

<script setup>
import router from '@/router'
import { computed, defineProps } from 'vue';
import {useStore} from "@/store";
import { t } from '@/controllerLK/GlobusLocalization'

import facebook_active from '@/assets/icons/facebook-active.png'
import facebook_disabled from '@/assets/icons/facebook-disabled.png'
import instagram_active from '@/assets/icons/instagram-active.png'
import instagram_disabled from '@/assets/icons/instagram-disabled.png'

const getImageUrl = (name) => {
  switch (name) {
    case 'facebook-active':
      return facebook_active
    case 'facebook-disabled':
        return facebook_disabled
    case 'instagram-active':
      return instagram_active
    case 'instagram-disabled':
      return instagram_disabled
  }
}

const props = defineProps({
  link: {
    type: Object,
    required: true,
  }
})

const mainStore = useStore()

const routerClick = () => {
  if (props.link.disabled) {
    mainStore.createNotify('Уведомление', 'Данная страница отключена.', 'error')
  } else if (router.currentRoute.value.fullPath !== props.link.route) {
    router.push(props.link.route)
  }
}

const isActive = computed(() => {
    return router.currentRoute.value.fullPath === props.link.route
})

</script>

<style lang="scss" scoped>
.routing-item {
  border-radius: 50%;
  transition: .2s;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  cursor: pointer;
  position: relative;
  border: none;

  &:hover {
    background: #F7F8FD;
    & .clue {
      opacity: 1;
    }
  }

  &.active {
    background: #F7F8FD;
  }

  & img {
    width: 30px;
    height: 30px;
  }
}

.clue {
  position: absolute;
  left: 50%;
  top: calc(100% - 4px);
  background: #fff;
  font-size: 14px;
  padding: 6px 14px;
  border-radius: 8px;
  opacity: 0;
  transition: .2s;
  transform: rotate(-50%, 0);
  z-index: 1;
}
</style>