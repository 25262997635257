<script setup>
import SvgIcon from "@/components/common/svgButton.vue";
import { t } from '@/controllerLK/GlobusLocalization'

const emit = defineEmits(['closeModal'])
const props = defineProps({
  isEdit: {
    type: Boolean,
    required: true,
    default: false
  }
})
</script>

<template>
  <div class="project-header">
    <div
      v-if="!isEdit"
      class="project-header__title"
    >
      {{ t('project_modal_header.title') }}
    </div>
    <div
      v-else
      class="project-header__title"
    >
      {{ t('project_modal_header.title_edit') }}
    </div>

    <div

      class="project-header__close"
      @click="emit('closeModal')"
    >
      <SvgIcon
        name="x"
        width="20px"
        height="20px"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/vars.scss';

.project-header {
  display: flex;
  justify-content: space-between;
  &__title {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    color: $text-01;
  }

  &__close {
    cursor: pointer;
  }
}
</style>