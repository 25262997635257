<template>
  <GlobusHeader v-if="router.currentRoute.fullPath !== 'login'" />
  <RouterView :key="language" />
  <NotificationBlock />
  <GlobalLoader v-if="STORE.loader" />
</template>

<script setup>
import GlobalLoader from '@/components/common/Preloader/GlobalLoader.vue';
import GlobusHeader from '@/components/GlobusHeader.vue';
import NotificationBlock from '@/components/Notification/NotificationBlock.vue';
import { getToken } from '@/composables/auth';
import {useStore} from '@/store'
import {changeShow, changeLoadApp, setProject} from '@/composables/ModuleCommunication'
import {modules} from '@/composables/constans/modules'
import { useProjects } from './store/projects';
import { useUser } from '@/store/user';
import { exit } from '@/composables/functionExit';
import {onMounted, ref} from "vue";
import {useRouter} from "vue-router";
import {useGlobusLocal} from "@/controllerLK/GlobusLocalization";
const { initGlobusLocal } = useGlobusLocal()



const STORE = useStore()
const storeProjects = useProjects()
const userStore = useUser()
const router = useRouter()

const language = ref(localStorage.getItem('globusLanguage'))

onMounted(() => {
  STORE.loader = true
  setListener()
})

const setListener = () => {
  window.addEventListener("SetModule", async (event) => {
    const module = modules.find(item => item.dataName === event.detail.module)
    if(module) {
      STORE.activeModule = module
    } else {
      STORE.activeModule = {name: 'Личный кабинет', dataName: 'module_lk', img: ''}
    }
    STORE.routing = event.detail.routing
    STORE.mainHrefApp = event.detail.path
    await init()
  })
  window.addEventListener("GoToLogin", () => {
    exit()
    changeShow(false)
  })
  window.addEventListener("getToken", async () => {
    const token = await getToken()
    if(token){
      return token
    } else {
      exit()
      changeShow(false)
    }
  })
}

const init = async () => {
  try {
    const token = await getToken()
    if (token) {
      await userStore.getUser()


      if (userStore.user.language.toLowerCase() !== localStorage.getItem('globusLanguage')) {
        localStorage.setItem('globusLanguage', userStore.user.language.toLowerCase())
        window.location.reload()
      }

      initGlobusLocal(userStore.user.language.toLowerCase(), 'personal_area')

      if(!userStore.user.modules.find(item => item.moduleId === STORE.activeModule.dataName) && STORE.activeModule.dataName !== 'module_proxy' ){
        exit()
        STORE.createNotify('Нет доступа к модулю',
            'У данного пользователя нет доступа к модулю, обратитесь к администратору', '')
        STORE.loader = false
        return
      }
      if(userStore.user.activeProject){
        await storeProjects.getProjects()
        let project = await storeProjects.getProject(userStore.user.activeProject)

        if(project){
          storeProjects.activeProject = project
          setProject(project)
          changeLoadApp(true)
          changeShow(true)
        }
      } else {
        changeLoadApp(false)
        changeShow(false)
      }
    }
  } catch(e){
    console.log(e)
  }
  STORE.loader = false
}
</script>
<style lang="scss">

input:focus {
  outline: none;
}

#appMain {
  font-family: Roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-width: 1440px;

}

#app {
  font-family: Roboto;
}

*, *::before, *::after {
  box-sizing: border-box;
}
p {
  padding: 0;
  margin: 0;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

ul,li {
  list-style: none;
  padding: 0;
  margin: 0;
}

</style>
