import router from "@/router/index";
import { useStore } from "@/store"
import {changeShow} from '@/composables/ModuleCommunication'
import { mainStorage } from "@/composables/tools/main-storage.tools";
import { useUser } from '@/store/user'
import { useProjects } from "@/store/projects";

export function exit(){
    const Store = useStore()
    mainStorage.setItem('access_token', '')
    mainStorage.setItem('access_time_end', 1)
    mainStorage.setItem('refresh_time_end', 1)
    mainStorage.setItem('timeEnd', 1)
    mainStorage.setItem('refresh_token', '')
    mainStorage.setItem('username', '')
    changeShow(false)
    Store.confirmExitModalIsActive = false
    clearData()
    router.push('/login')
}

export function confirmExit() {
    const Store = useStore()
    Store.confirmExitModalIsActive = true
}

export function clearData() {
    const userStore = useUser()
    const projectsStore = useProjects()
    userStore.clearUser()
    projectsStore.clearData()
}