import router from "@/router/index";
import { useStore } from "@/store/index"

export function changeShow(val){
    const event = new CustomEvent("ChangeShowModule", {detail: val});
    window.dispatchEvent(event);
}

export function changeLoadApp(val){
    const event = new CustomEvent("ChangeLoadModule", {detail: val});
    window.dispatchEvent(event);
}

export function setProject(project){
    const event = new CustomEvent("SetProject", {detail: project});
    window.dispatchEvent(event);
}

export function setUser(user){
    const event = new CustomEvent("SetUser", {detail: user});
    window.dispatchEvent(event);
}

export function closeLk(){
    changeShow(true)
    changeLoadApp(true)
}

export function goLkPage() {
    router.push('/lk')
}

export function goMainPage() {
    const Store = useStore()
    router.push(Store.mainHrefApp)
}