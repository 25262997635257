<script setup>

import {inject, ref, watch} from 'vue'
import SvgIcon from "@/components/common/svgButton.vue";
import {useProjects} from "@/store/projects";
import BlockView from "@/components/common/BlockView.vue";

const emit = defineEmits(['closeRegionSearch'])

const props = defineProps({
  isEdit: {
    type: Boolean,
    default: false
  }
})

const selectedRegionList = inject('selectedRegionList')

const searchString = ref('')

const isShowDropdown = ref(false)

const projectStore = useProjects()

const createProjectFormData = inject('createProjectFormData')

watch(() => searchString.value, (value) => {

  if (value.length === 0) {
    isShowDropdown.value = false
  } else {
    projectStore.searchRegion(value)
  }
})

const selectedRegion = ref(null)

const addRegionToList = () => {
  if (selectedRegion.value?.name === searchString.value) {
    if (props.isEdit) {
      projectStore.createMap(createProjectFormData.value.id, selectedRegion.value.id, selectedRegion.value.name)
    }
    selectedRegionList.value.push(selectedRegion.value)
    searchString.value = ''
    emit('closeRegionSearch')
  } else {
    isError.value = true
    console.log('Ошибка. Неверное название')
  }
}

const isError = ref(false)

</script>

<template>
  <div class="wrapper">
    <div class="globus-combo-box">
      <input
        v-model="searchString"
        class="globus-combo-box__input"
        :class="{'globus-combo-box__input_error': isError}"
        type="text"
        @focusin="isShowDropdown = true"
      >
      <div
        class="globus-combo-box__button"
        @click="addRegionToList"
      >
        <SvgIcon
          name="arrow"
          width="16px"
          height="16px"
        />
      </div>
    </div>
    <div
      v-if="isShowDropdown && projectStore.foundedRegionList.length > 0"
      class="globus-combo-box__dropdown"
    >
      <div
        v-for="item in projectStore.foundedRegionList"
        :key="item"
        class="dropdown-item"
        @click="searchString = item.name; isShowDropdown = false; selectedRegion = item"
      >
        {{ item.name }}
      </div>
    </div>
  </div>

  <BlockView
    v-if="isShowDropdown"
    @click="isShowDropdown = false"
  />
</template>

<style scoped lang="scss">
@import '@/assets/styles/vars.scss';

input {
  all: unset;
}

.wrapper {
  position: relative;

  .globus-combo-box {
    display: flex;
    height: 40px;
    border: 1px solid #EEEEEE;
    border-radius: 8px;

    &__input {
      font-size: 14px;
      font-weight: 400;
      display: flex;
      height: 100%;
      color: $globus-background-secondary-text-color;

      &_error {
        border: 1px solid red;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

    }

    &__button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 100%;
      border-top-right-radius: 8px;

      border-bottom-right-radius: 8px;
      background: $globus-button-primary-color;
    }

    &__dropdown {
      z-index: 2000;
      position: absolute;
      top: 50px;
      width: 100%;
      background: $globus-background-secondary-color;
      box-shadow: 1px 2px 10px 0 #131E2726;
      border-radius: 8px;
      padding: 6px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      max-height: 200px;
      overflow-x: hidden;

      .dropdown-item {
        cursor: pointer;
        user-select: none;
        padding: 10px;
        border-radius: 8px;

        &:hover {
          background-color: $globus-button-secondary-hover-color;
        }
      }

    }
  }
}



</style>