<script setup>
import SearchInput from "@/components/LKPage/Input/SearchInput.vue";
import {onMounted, ref, defineProps, computed, watch} from "vue";
import {useProjects} from "@/store/projects";
import SvgIcon from "@/components/common/svgButton.vue";
import ProjectModal from "@/components/LKPage/LKWindow/ProjectWindow/ProjectModal.vue";
import BlockView from "@/components/common/BlockView.vue";
import DeleteProjectModal from "@/components/LKPage/LKWindow/ProjectWindow/DeleteProjectModal.vue";
import {useUser} from "@/store/user";
import {setProject} from "@/composables/ModuleCommunication";
import {useStore} from "@/store";

const mainStore = useStore()
const projectStore = useProjects()
const userStore = useUser()

const projectList = ref([])
const isEdit = ref(false)
const projectSearchString = ref('')
const activePage = ref(1)

const props = defineProps({
  isArchive: {
    type: Boolean,
    default: false
  }
})

onMounted(async () => {
  await projectStore.getProjects()
  updateProjectList()
})

const updateProjectList = () => {
  if (props.isArchive) {
    projectList.value = projectStore.projects.filter((project) => project.isArchive === true)
  } else {
    projectList.value = projectStore.projects.filter((project) => project.isArchive === false)
  }
}

/* Пагинатор **/

const rowPerPage = computed(() => {
  if (props.isArchive) {
    return 16
  } else {
    return 15
  }
})

/* Количество страниц **/
const pages = computed(() => {
  const items = projectList.value.length
  let pagesCount = 1

  if (items % rowPerPage.value === 0 ) {
    pagesCount = items / rowPerPage.value
  } else {
    pagesCount = items / rowPerPage.value
    pagesCount = parseInt(pagesCount) + 1
  }

  return pagesCount
})

/* Получение списка проектов на текущию страницу **/
const getPageItems = computed(() => {
  let projects = projectStore.projects.filter((project) => project.isArchive === props.isArchive)
  if (projectSearchString.value.length > 0) {
    return projects.filter((project) => {
      return project.name.toLowerCase().includes(projectSearchString.value.toLowerCase())
    })
  } else {

    return projects.slice((activePage.value - 1) * rowPerPage.value, activePage.value * rowPerPage.value);
  }
})

/* Переключение на предыдущию страницу **/
const prevPage = () => {
  if (activePage.value !== 1) {
    activePage.value -= 1;
  }
}

/* Переключение на следующию страницу **/
const nextPage = () => {
  if (activePage.value !== pages.value) {
    activePage.value += 1;
  }
}

/* Модальные окна. CRUD **/

const createProjectModalShow = ref(false)
const toggleCreateProjectModal = () => {
  isEdit.value = false
  createProjectModalShow.value = !createProjectModalShow.value
}

const editProjectModalShow = ref(false)
const toggleEditProjectModal = () => {
  isEdit.value = true

  showSelectedProject.value = null

  createProjectModalShow.value = !createProjectModalShow.value
}

const deleteProjectModalShow = ref(false)
const showDeleteProjectModal = () => {
  deleteProjectModalShow.value = !deleteProjectModalShow.value
  hideProjectMenu()
}

/* Отображение меню проекта при клике на три точки. Одна переменная для отображения меню,
   вторая для передачи проекта в компоненты модальных окон **/
const selectedProject = ref(null)
const showSelectedProject = ref(null)
const showProjectMenu = (project) => {
  showSelectedProject.value = project
  selectedProject.value = project
}

const hideProjectMenu = () => {
  showSelectedProject.value = null
  selectedProject.value = null
}

/* Перенос проекта в архив **/
const moveToArchive = async () => {
  selectedProject.value.isArchive = true
  await projectStore.updateProject(selectedProject.value).then(() => {
    projectList.value = projectList.value.filter((item) => item !== selectedProject.value)
    selectedProject.value = null
  })
}

/* Перенос проекта из архива **/
const moveFromArchive = async () => {
  selectedProject.value.isArchive = false
  await projectStore.updateProject(selectedProject.value).then(() => {
    projectList.value = projectList.value.filter((item) => item !== selectedProject.value)
    selectedProject.value = null
  })
}

/* Добавление суффикса **/
function addOrdinal(n) {
  const s = ["ков", "к", "ка", "ка"]
  let v = n % 100

  return (s[(v - 20) % 10] || s[v] || s[0])
}

/* Сделать выбранный проект активным **/

const setActiveProject = (project) => {

  if (props.isArchive) return false

  mainStore.loader = true
  projectStore.setActiveProject(project.id)
  setProject(project)
  userStore.user.activeProject = project.id
  selectedProject.value = null
  showSelectedProject.value = null
  mainStore.loader = false
}

</script>

<template>
  <div class="window-wrapper">
    <div class="header">
      <div class="header__title">
        Список проектов
      </div>
      <div class="header__search">
        <SearchInput
          v-model="projectSearchString"
        />
      </div>
    </div>

    <div class="body">
      <div
        v-if="isArchive === false"
        class="new-project"
        @click="createProjectModalShow = true"
      >
        <div class="new-project__title">
          <SvgIcon
            width="42px"
            height="42px"
            :name="'plus'"
          />
        </div>
        <div
          class="new-project__title"
        >
          Новый проект
        </div>
      </div>

      <div
        v-for="project in getPageItems"
        :key="project"
        class="project"
        :class="{'project-active': project.id === userStore.user.activeProject}"
        @click.self="setActiveProject(project)"
      >
        <div
          class="project-module"
          @click="setActiveProject(project)"
        >
          <div
            v-for="module in project.modules"
            :key="module"
          >
            <div
              v-if="module !== 'module_main'"
              class="project-module__icon"
            >
              <SvgIcon

                class="svg-icon"
                width="29px"
                height="29px"
                :name="module"
              />
            </div>
          </div>
        </div>


        <div
          class="project-body"
        >
          <div
            class="project-body__title"
            @click="setActiveProject(project)"
          >
            Проект {{ project.name }}
          </div>
          <div
            class="project-body__button"
            @click="showProjectMenu(project)"
          >
            <SvgIcon
              width="14px"
              height="14px"
              :name="`burger-mini`"
            />
          </div>
          <div
            v-if="showSelectedProject === project && deleteProjectModalShow === false
              && editProjectModalShow === false
              && createProjectModalShow === false"
            class="project-body-menu"
          >
            <div
              class="project-body-menu__item"
              @click="toggleEditProjectModal"
            >
              <SvgIcon
                width="14px"
                height="14px"
                :name="`edit`"
              />
              Настройки проекта
            </div>

            <div
              v-if="isArchive === false"
              class="project-body-menu__item"
              @click="moveToArchive()"
            >
              <SvgIcon
                width="14px"
                height="14px"
                :name="`archive`"
              />
              Добавить в архив
            </div>

            <div
              v-else
              class="project-body-menu__item"
              @click="moveFromArchive()"
            >
              <SvgIcon
                width="14px"
                height="14px"
                :name="`archive`"
              />
              Перенести в активные
            </div>

            <div
              class="project-body-menu__item"
              @click="deleteProjectModalShow = true"
            >
              <SvgIcon
                width="14px"
                height="14px"
                :name="`delete`"
              />
              Удалить
            </div>
          </div>
        </div>
        <div
          class="project__member"
          @click.self="setActiveProject(project)"
        >
          {{ project.users.length }} участни{{ addOrdinal(project.users.length) }}
        </div>
      </div>
    </div>

    <div class="footer">
      <div
        v-if="pages > 1"
        class="paginator"
      >
        <div
          class="paginator__prev paginator__item"
          @click="prevPage"
        >
          &lt;
        </div>
        <div
          v-for="page in pages"
          :key="page"
          class="paginator__item"
          :class="{'paginator__item-active': page === activePage}"
          @click="activePage = page"
        >
          {{ page }}
        </div>
        <div
          class="paginator__next paginator__item"
          @click="nextPage"
        >
          >
        </div>
      </div>
    </div>
  </div>

  <ProjectModal
    :show="createProjectModalShow"
    :is-edit="isEdit"
    :is-archive="isArchive"
    :project="selectedProject"
    @close-modal="toggleCreateProjectModal"
    @update-project-list="updateProjectList()"
  />

  <DeleteProjectModal
    :project-id="selectedProject?.id"
    :project-name="selectedProject?.name"
    :show="deleteProjectModalShow"
    @close-modal="showDeleteProjectModal"
  />


  <BlockView
    v-if="showSelectedProject"
    @click="selectedProject=null; showSelectedProject = null;
            deleteProjectModalShow = false; createProjectModalShow = false"
  />
</template>

<style scoped lang="scss">

@import '@/assets/styles/vars';

.window-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 33px;

  height: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    gap: 5px;

    &__title {
      font-size: 28px;
      font-weight: 400;
      text-wrap: nowrap;
    }

    &__search {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .body {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 12px;
    margin-bottom: 20px;


    .project {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      width: 19.1vw;
      min-width: 150px;
      height: 130px;

      padding: 20px;
      cursor: pointer;
      border: 1px solid $globe-passive-color;

      border-radius: 8px;
      gap: 8px;

      .project-module {
        display: flex;
        flex-wrap: wrap;


        &__icon {


          border: 1px solid white;
          border-radius: 50%;


        }
      }

      &:hover {
        border: none;
        background: $hover-active-color;
      }

      &-active {
        border-color: $globe-active-color;
      }

      &-body {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;

        &__title {
          font-size: 14px;

          font-weight: 500;

          display: inline-block;
          overflow: hidden;
          width: 100%;
          text-align: start;
          white-space: nowrap;
          text-decoration: none;
          text-overflow: ellipsis;
          color: $text-01;
        }

        &__button {
          width: 14px;
          height: 14px;
          z-index: 100;
        }

        &-menu {
          position: absolute;
          z-index: 2000;
          top: 0;
          left: 95px;
          width: 210px;
          min-height: 120px;
          padding: 6px;
          border-radius: 8px;
          background: #FFFFFF;
          box-shadow: 1px 2px 10px 0 #131E2726;

          &__item {
            font-size: 14px;
            font-weight: 400;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            height: 40px;
            padding-left: 8px;
            border-radius: 8px;
            gap: 4px;

            &:hover {
              background: $hover-active-color;
            }
          }
        }
      }
    }

    .new-project {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 19.1vw;

      min-width: 150px;
      height: 130px;
      cursor: pointer;

      color: #FFFFFF;
      border-radius: 8px;
      background: $blue;

      &__title {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;

    .paginator {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;

      &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        cursor: pointer;

        &-active {
          border: 1px solid $blue;
          border-radius: 4px;
        }
      }
    }
  }
}

</style>