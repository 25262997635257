import {createApp, h} from 'vue'
import App from './App.vue'
import router from '@/router/index'
import {createPinia} from 'pinia';
import { moduleInit } from './controllerLK/initLK';
import { initRouterLK } from './controllerLK/initLK';
import { LK } from './controllerLK/LKstore';
import {useStore} from "@/store/index.js"
import {axiosInit} from "@/composables/axios";
import axios from "axios";

import VueTheMask from 'vue-the-mask'
import {useLK} from "@/store/lk-store";


const app = createApp({
    mounted() {
        const LKStore = useLK();
        axiosInit(axios, LKStore)
        console.log('[START] Start lk Application v ', process.env.VUE_APP_VERSION);
    },
    render: () => h(App)
})

const pinia = createPinia()

app.use(pinia)
app.use(router)
app.use(VueTheMask)



window.moduleInit = moduleInit
window.initRouterLK = initRouterLK
window.LKAxios = axiosInit
window.LKStore = LK
window.storeLK = useStore()

app.mount('#appMain')

