<script setup>
import { defineModel } from 'vue'
import SvgIcon from "@/components/common/svgButton.vue";
import { t } from '@/controllerLK/GlobusLocalization'
const model = defineModel()
</script>

<template>
  <div class="search-input-wrapper">
    <input
      v-model="model"
      type="text"
      :placeholder="t('lk_project_window_search.placeholder')"
    >
    <div class="search-icon">
      <SvgIcon
        name="search"
        width="15px"
        height="15px"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/vars';

.search-input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

input {
  overflow: hidden;
  width: 180px;
  min-width: 85px;
  height: 36px;
  padding: 10px;
  transition: ease all .5s;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  border-top: 1px solid $grey-light;
  border-right: none;
  border-bottom: 1px solid $grey-light;
  border-left: 1px solid $grey-light;
  border-radius: 8px 0 0 8px;
  gap: 10px;

  @media (max-width: 750px) {
    width: 85px;
  }

}

.search-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 36px;
  padding-right: 10px;
  border-top: 1px solid $grey-light;
  border-right: 1px solid $grey-light;
  border-bottom: 1px solid $grey-light;
  border-left: none;
  border-radius: 0 8px 8px 0;
}
</style>