<script setup>
import {ref} from "vue";
import BlockView from "@/components/common/BlockView.vue";
import HeaderProjectMenu from "@/components/GlobusHeader/HeaderMenu/HeaderProjectMenu.vue";
import UserProfile from "@/components/GlobusHeader/HeaderMenu/UserProfile.vue";
import HeaderLanguage from "@/components/GlobusHeader/HeaderMenu/HeaderLanguage.vue";
import BurgerMenu from "@/components/GlobusHeader/HeaderMenu/BurgerMenu.vue";

const openNav = ref(false)
const openParam = ref(false)
const popupProjectsOn = ref(false)

const togglePopupVisible = () => {
  popupProjectsOn.value = !popupProjectsOn.value;
}

const toogleProfileVisible = () => {
  openParam.value = !openParam.value
}

const toggleModuleMenuVisible = () => {
  openNav.value = !openNav.value
}


</script>

<template>
  <BlockView
    v-if="openParam || openNav || popupProjectsOn"
    @click="openParam = false; openNav = false; popupProjectsOn = false"
  />
  <div class="header-menu">
    <HeaderProjectMenu
      :open-nav="openNav"
      :open-langs="(lang) => openLangs(lang)"
      :popup-projects-on="popupProjectsOn"
      @toggle-popup-visible="togglePopupVisible"
    />

    <UserProfile
      :open-param="openParam"
      @toogle-profile-visible="toogleProfileVisible"
    />

    <HeaderLanguage />

    <BurgerMenu
      :open-nav="openNav"
      @toggle-module-menu-visible="toggleModuleMenuVisible"
    />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/styles.scss';

.header-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
</style>