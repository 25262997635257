<script setup>
import UserAvatar from "@/components/LKPage/LKWindow/ProfileWindow/UserAvatar.vue";
import {useUser} from "@/store/user";
import {onMounted, ref} from "vue";
import TextInput from "@/components/LKPage/LKWindow/ProfileWindow/Input/TextInput.vue";

const props = defineProps({
  isEdit: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:isEdit'])

const userStore = useUser()

onMounted(async () => {
  await userStore.getUser().then(() => {
    userFormData.value = JSON.parse(JSON.stringify(userStore.user));
  })
})

const userFormData = ref({
  firstName: '',
  middleName: '',
  lastName: '',
  phone: '',
  image: '',
  email: ''
})

const errorList = ref({
  firstName: false,
  middleName: false,
  lastName: false,
  phone: false,
  email: false
})


const saveUser = async () => {
  errorList.value = {
    firstName: false,
    middleName: false,
    lastName: false,
    phone: false,
    email: false
  }

  if (userFormData.value.lastName !== null && userFormData.value.lastName.length <= 0) {
    errorList.value.lastName = 'Поле обязательно для заполнения'
  } else {
    errorList.value.lastName = validateName(userFormData.value.lastName)
  }

  if (userFormData.value.middleName !== null && userFormData.value.middleName.length <= 0) {
    errorList.value.middleName = 'Поле обязательно для заполнения'
  } else {
    errorList.value.middleName = validateName(userFormData.value.middleName)
  }

  if (userFormData.value.firstName !== null && userFormData.value.firstName.length <= 0) {
    errorList.value.firstName = 'Поле обязательно для заполнения'
  } else {
    errorList.value.firstName = validateName(userFormData.value.firstName)
  }


  if (userFormData.value.email !== null && userFormData.value.email.length === 0) {
    errorList.value.email = 'Поле обязательно для заполнения'
  } else if (!validateEmail(userFormData.value.email)) {
    errorList.value.email = 'Неверный формат e-mail адреса. Прим. user@globus.ru'
  }



  if (userFormData.value.phone !== null && userFormData.value.phone.length <= 0) {
    errorList.value.phone = 'Поле обязательно для заполнения'
  }
  else if (!validatePhone(userFormData.value.phone)) {
    errorList.value.phone = 'Неверный формат номера телефона. Прим. +7 (800) 555-35-35'
  }

  for (const error in errorList.value) {
    if (errorList.value[error]) return false
  }

  userFormData.value.lastName = userFormData.value.lastName.trim()
  userFormData.value.middleName = userFormData.value.middleName.trim()
  userFormData.value.firstName = userFormData.value.firstName.trim()

  await userStore.updateUser(userFormData.value).then(() => {
    emit('update:isEdit', false)
  })
}

const validatePhone = (phone) => {
  const re = /^(\+7|7|8)?[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/gm
  return re.test(phone)
}

const validateEmail = (email) => {
  const re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i

  const startMail = email.split('@')
  const endMail = startMail[1].split('.')

  if (startMail[0].startsWith('-')) return false
  if (startMail[0].endsWith('-')) return false
  if (startMail[0].startsWith('_')) return false
  if (startMail[0].endsWith('_')) return false

  if (endMail[0].startsWith('_')) return false
  if (endMail[0].endsWith('-')) return false
  if (endMail[0].startsWith('_')) return false
  if (endMail[0].endsWith('_')) return false

  return re.test(email)
}

const validateName = (name) => {
  // eslint-disable-next-line
  const emojiRegEx = new RegExp(/[^\x00-\x7Fа-яА-Я]/g)
  const re = /[\d\w(!@#$%^&*())(!"№;%:?*+=)]/giu


  if (emojiRegEx.test(name)) return 'Разрешено использовать только кириллицу'
  if (re.test(name)) return 'Разрешено использовать только кириллицу'
  if (name.startsWith('-')) return 'Разрешено использовать только кириллицу'
  if (name.endsWith('-')) return 'Разрешено использовать только кириллицу'

  // Заглавные буквы

  if (name[0] !== name[0].toUpperCase()) return 'Неверный формат'

  for (let char in name) {
    if (char !== '0') {
      if (name[char] !== name[char].toLowerCase()) {
        return 'Неверный формат'
      }
    }
  }

  return false

}
</script>

<template>
  <div class="header">
    <UserAvatar
      :is-edit="isEdit"
      :image="userFormData.image"
      @update:image="image => userFormData.image = image"
    />
    <div class="header__title">
      {{ userStore.user.firstName }} {{ userStore.user.lastName }}
    </div>
  </div>

  <div class="body">
    <div class="user-control">
      <div
        class="user-control__button user-control__button_cancel"
        @click="emit('update:isEdit', false)"
      >
        <div>
          Отмена
        </div>
      </div>

      <div
        class="user-control__button"
        @click="saveUser"
      >
        <div>
          Сохранить изменения
        </div>
      </div>
    </div>
    <div class="user-block">
      <div class="user-information">
        <div class="user-information__title">
          E-mail
        </div>
        <div class="user-information__value">
          <TextInput
            :value="userFormData.email"
            place-holder="user@globus.ru"
            :error-message="errorList.email"
            @update:value="newValue => userFormData.email = newValue"
          />
        </div>
      </div>

      <div class="user-information">
        <div class="user-information__title">
          Телефон
        </div>
        <div class="user-information__value">
          <TextInput
            :value="userFormData.phone"
            :error-message="errorList.phone"
            place-holder="+7 (800) 555-35-35"
            :mask="`+7 (###) ###-##-##`"
            type="tel"
            @update:value="newValue => userFormData.phone = newValue"
          />

          <!--  TODO: Нет реализации со стороны бэка. Номер телефона должен быть разделен на код страны и цифры      -->
          <!--            <CountryDropdown-->
          <!--              :country-value="phoneCountry"-->
          <!--              :value="userFormData.phone"-->
          <!--              :error="errorList.some((error) => error.type === 'phone')"-->
          <!--              :error-message="errorList.find((error) => error.type === 'phone')?.message"-->
          <!--              type="tel"-->
          <!--              @update:country-value="newValue => phoneCountry = newValue"-->
          <!--              @update:value="newValue => userFormData.phone = newValue"-->
          <!--            />-->
        </div>
      </div>

      <div class="fullname-wrapper">
        <div class="user-information">
          <div class="user-information__title">
            Фамилия
          </div>
          <div class="user-information__value">
            <TextInput
              :error-message="errorList.lastName"
              :value="userFormData.lastName"
              place-holder="Иванов"
              @update:value="newValue => userFormData.lastName = newValue"
            />
          </div>
        </div>

        <div class="user-information">
          <div class="user-information__title">
            Имя
          </div>
          <div class="user-information__value">
            <TextInput
              :error-message="errorList.firstName"
              :value="userFormData.firstName"
              place-holder="Иван"
              @update:value="newValue => userFormData.firstName = newValue"
            />
          </div>
        </div>

        <div class="user-information">
          <div class="user-information__title">
            Отчество
          </div>
          <div class="user-information__value">
            <TextInput
              :error-message="errorList.middleName"
              :value="userFormData.middleName"
              place-holder="Иванович"
              @update:value="newValue => userFormData.middleName = newValue"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/vars.scss';

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: center;
  height: 20%;

  &__title {
    font-size: 28px;
    font-weight: 400;
  }
}

.body {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 80%;
  width: 100%;
  gap: 12px;

  .user-control {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 4px;
    .user-control__button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 140px;
      max-width: 200px;
      padding: 0 5px;
      height: 40px;
      background-color: $blue-dark;
      color: #FFFFFF;
      border-radius: 8px;
      gap: 8px;
    }

    .user-control__button_cancel {
      background-color: #FFFFFF;
      color: $grey-dark;
      border: 1px solid #E0E0E0;
    }

  }

  .user-block {
    background-color: $light-blue;
    border-radius: 10px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    height: 100%;
    width: 100%;

    .user-control {

      &__edit {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 120px;
        padding: 0 5px;
        height: 40px;
        background-color: #FFFFFF;
        color: $grey-dark;
        border: 1px solid #E0E0E0;
        border-radius: 8px;
        gap: 8px;
      }
    }

    .user-information {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;

      &__title {
        font-weight: 600;
      }
      &__value {
        color: #898989;
        text-align: start;
      }
    }
  }
}

.fullname-wrapper {
  display: flex;
  gap: 8px;

  @media (max-width: 850px) {
    flex-direction: column;
  }
}
</style>