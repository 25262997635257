<template>
  <div
    id="project-wrapper"
    :class="{'project-wrapper': router.currentRoute.fullPath !== '/login',
             'title-login-box': router.currentRoute.fullPath === '/login'}"
  >
    <ProjectHeaderLogo />
    <ProjectHeaderRouting />
  </div>
</template>

<script setup>
import ProjectHeaderLogo from "@/components/GlobusHeader/HeaderProject/ProjectHeaderLogo.vue";
import ProjectHeaderRouting from "@/components/GlobusHeader/HeaderProject/ProjectHeaderRouting.vue";
import {useRouter} from "vue-router";



const router = useRouter()

</script>

<style lang="scss" scoped>
@import '@/assets/styles/styles';

.project-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
  height: 50px;
  background: #fff;
  border-radius: 30px;
  padding: 5px 20px 5px 20px;
  box-shadow: 0 0 33px 0 #7090B029;
  user-select: none;
}

.title-login-box {
  display: flex;
  align-items: center;
  justify-content:center;
}
</style>