import { createRouter, createWebHistory  } from 'vue-router'
import LKPage from '../pages/LKPage.vue'
import Login from '../pages/LoginPage.vue'
import { mainStorage } from '@/composables/tools/main-storage.tools'
import { goMainPage } from '@/composables/ModuleCommunication'
import { useStore } from '@/store'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/lk',
    name: 'lk',
    component: LKPage
  },
]

const history = createWebHistory()

const router = createRouter({
  history: history,
  routes
})

router.beforeEach(async (to, from, next) => {
  const Store = useStore()
  
  if (window.hasUnsavedChanges) {
    window.dispatchEvent(new Event('showUnsavedChangesModal')); // Инициируем показ модального окна
    
    // Ждем подтверждения выхода
    const handleConfirmLeave = () => {
      router.push(to);
      window.removeEventListener('confirmLeave', handleConfirmLeave);
    }

    window.addEventListener('confirmLeave', handleConfirmLeave);
    next(false); // Останавливаем переход
    return
  }

  if(to.name === 'lk') {
    document.querySelector("#appMain").style.backgroundColor = 'rgb(244, 250, 255)';
    document.querySelector("#appMain").style.height = '100vh';
  } else {
    document.querySelector("#appMain").style.backgroundColor = '';
    document.querySelector("#appMain").style.height = null
  }

  if(to.name === 'login') {
    if(mainStorage.getItem('access_token')) {
      goMainPage()
    }
  }

  if(!mainStorage.getItem('access_token')) {
    if(to.name !== 'login') {
      router.push('login')
    }
  } else if(to.name === 'login'){
    goMainPage()
  } 
  else if(to.fullPath === '/' && Store.mainHrefApp){
    goMainPage()
  }
  next()
  if(window.routerApp) {
    window.routerApp.replace(to)
  }
});
window.routerLK = router
export default router
