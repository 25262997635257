<template>
    <div class="notification">
        <!-- <img class="btn-close"  @click="dellNotify()" :src="require('@/assets/personal-area/icon-close.svg')" > -->
        <SvgButton style="position: absolute;right: 4vh;top: 4vh;cursor: pointer;" height="20" width="20" name="icon-close" @action="dellNotify()" />
        <p class="notification-header" :style="`color:${item.color}`">{{item.header}}</p>
        <p class="notification-description" v-if="item.message">{{item.message}}</p>
    </div>
</template>

<script>
import SvgButton from '@/components/common/svgButton.vue'
import {useStore} from "@/store/index.js"
    export default {
        components:{SvgButton},
        props:['item'],
        data(){
            return {
                Store: useStore(),
            }
        },
        created(){
            console.log(this.item)
            setTimeout(()=>{
                this.dellNotify()
            }, this.item.lifeTime)
        },
        methods:{
            dellNotify(){
                let index =  this.Store.notifications.findIndex(it => it.message == this.item.message)
                this.Store.notifications.splice(index, 1)
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '@/style/styles.scss';
.notification-header{
    font-family: Roboto;
    font-size: 1.9vh;
    font-weight: 700;
    line-height: 2.7vh;
    width: 90%;
    text-align: center;
}
.notification-description{
    font-family: Roboto;
    font-size: 1.6vh;
    font-weight: 400;
    line-height: 2.6vh;
    margin-top:1vh
}
.notification{
    margin-top: 1vh;
    box-sizing: border-box;
    padding: 1vh 1vh 1vh 1vh;
    min-height: 5vh;
    border-radius: $border-radius;
    width: 100%;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    background-color: rgba(255, 255, 255, 1);
}
.btn-close{
    position: absolute;
    right: 4vh;
    top: 4vh;
    cursor: pointer;
}
</style>