<script setup>
import SvgIcon from "@/components/common/svgButton.vue";
import {computed, inject} from "vue";
import {useUser} from "@/store/user";
import { t } from '@/controllerLK/GlobusLocalization'

const userStore = useUser()

const createProjectFormData = inject("createProjectFormData")

const errorList = inject("errorList");

const availableModuleList = computed(() => {
  return userStore?.user?.modules.filter(item => item.moduleName !== 'Главный')
})

/* Добавление модуля в список **/
const toggleModule = (module) => {
  const findModule = createProjectFormData.value.modules.filter((item) => item === module)
  if (findModule.length > 0) {
    createProjectFormData.value.modules = createProjectFormData.value.modules.filter((item) => item !== module)
  } else {
    createProjectFormData.value.modules.push(module)
  }
}

/* Проверка добавлен ли модуль для css класса  **/
const isActiveModule = (module) => {
  const findModule = createProjectFormData.value.modules.filter((item) => item === module)
  return findModule.length > 0;
}
</script>

<template>
  <div class="project-module-list-wrapper">
    <div class="project-module-list">
      <div
        v-for="module in availableModuleList"
        :key="module"
        class="project-module-list__item"
        :class="{'project-module-list__item-active': isActiveModule(module?.moduleId)}"
        @click="toggleModule(module?.moduleId)"
      >
        <SvgIcon
          :name="module?.moduleId"
          width="21px"
          height="21px"
        />
        {{ t('project_modal_modules.' + module.moduleId) }}
      </div>
    </div>
    <div
      v-if="errorList.filter((i) => i === 'noModule').length > 0"
      class="text-error"
    >
      {{ t('project_modal_modules.error.required') }}
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/vars.scss';

.project-module-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  &__item {
    user-select: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 35px;
    border-radius: 22px;
    gap: 5px;
    padding: 4px 12px 4px 12px;
    border: 1px solid $grey-light;
    color: $blue;

    &:hover {
      background: $hover-active-color;
      border-color: $hover-active-color;
    }

    &-active {
      border-color: $globe-active-color;

      &:hover {
        border-color: $globe-active-color;
      }
    }


  }
}
</style>