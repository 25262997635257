<script setup>

import ServicesBlock from "@/components/GlobusHeader/HeaderMenu/BurgerMenu/ServicesBlock.vue";
import SvgButton from "@/components/common/svgButton.vue";
import {useUser} from "@/store/user";

const userStore = useUser()

const props = defineProps(['openNav'])
const emit = defineEmits(['toggleModuleMenuVisible'])

</script>

<template>
  <div
    :class="{'burger-menu_selected': openNav}"
    class="burger-menu"
  >
    <SvgButton
      v-if="userStore.user?.modules?.length > 1"
      :height="'45px'"
      :width="'45px'"
      name="burger-header"
      @action="emit('toggleModuleMenuVisible')"
    />
    <div
      v-if="openNav"
    >
      <ServicesBlock />
    </div>
  </div>
</template>

<style scoped lang="scss">

.burger-menu {
  cursor: pointer;
}

.burger-menu_selected {
  border-radius: 50%;
  background: #FFFFFF;
  user-select: none;
}
</style>