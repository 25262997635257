<template>
  <div
    v-if="projectStore.projects.length > 0"
    class="panel-box"
  >
    <div class="panel__list scroll">
      <div
        v-if="projectStore.activeProject"
        class="panel__list-item"
      >
        <div class="active-project">
          {{ projectStore.activeProject.name }}
        </div> <span>{{ t('header_project_menu_block.current_project') }}</span>
      </div>
      <div
        v-for="(item, i) in projectList"
        :key="i"
        class="panel__list-item"
        @click="setActiveProject(item)"
      >
        <div class="no-active-project">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { t } from '@/controllerLK/GlobusLocalization'
import {useProjects} from "@/store/projects";
import {computed, onMounted, ref} from "vue";
import {setProject} from "@/composables/ModuleCommunication";
import {useStore} from "@/store";
import {useUser} from "@/store/user";

const userStore = useUser()

onMounted(async () => {
  if (!projectStore.projects.length || projectStore.projects.length === 0) {
    await projectStore.getProjects()
  }
})

const projectStore = useProjects()
const store = useStore()

const projectList = computed(() => {
  return projectStore.projects.filter((project) => {
    return project.id !== projectStore.activeProject.id && project.isArchive === false
  })
})

const setActiveProject = (project) => {
  store.loader = true
  projectStore.setActiveProject(project.id)
  userStore.user.activeProject = project.id
  setProject(project)
  store.loader = false
}

</script>

<style
    lang="scss"
    scoped
>
@import '@/assets/styles/styles';

.panel-box {
  height: 100%;
}

.panel__list {

  overflow-y: scroll;
  height: 96px;

  &-item {
    display: flex;
    justify-content: space-between;
    height: 32px;
    border-radius: 8px;
    padding: 6px 14px 6px 14px;
    font-size: 14px;
    line-height: 20px;

    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      background-color: #F7F8FD;
    }

    .active-project {
      max-width: 140px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .no-active-project {
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    span {
      color: #A7A7A7;
    }

  }
}
</style>