<template>
    <button class="btn" :class="clases">
        <slot name="icon">
        </slot>
        <SvgButton v-if="icon" :name="icon" :WIDTH="iconWidth ? iconWidth: '3vh'" :HEIGHT="iconHeight ? iconHeight: '3vh'" />
        <span >{{ text }}</span>
    </button>
</template>
<script>
import SvgButton from './svgButton.vue';
export default{
  components:{SvgButton},
  props:['text', 'icon', 'iconWidth', 'iconHeight', "clases"]
}
</script>
<style lang="scss" scoped>
@import '@/style/styles.scss';
.btn {
  display: flex;
  align-items: center;
  border-radius: 0.556vw;
  background: #1A64D4;
  padding: 8px 1.042vw;
  min-height: 2.639vw;
  color: #fff;
  font-size: 1.111vw;
  transition: background 0.3s ease 0s;
  cursor: pointer;
  display: flex;
  gap: 6px;
  box-sizing: border-box;
  justify-content: center;
  &:hover{
    background: #104696;
    color: #fff;
  }
  &:active{
    background: #104AA1;
  }
  &:disabled{
    background: #4786E633 !important;
  }
  &:focus{
    background: #1F9AFF;
  }
  &_small{
    min-height: 2.083vw;
  }
  &.border {
    background: transparent;
    color: #000;
    border: 1px solid #E0E0E0;
    &:hover{
      background: transparent;
      color: #000;
      border: 1px solid #E0E0E0;
    }
    &:active{
      background: transparent;
      color: #000;
      border: 1px solid #E0E0E0;
    }
    &:disabled{
      background: #969696 !important;
    }
    &:focus{
      background: transparent;
      color: #000;
      border: 1px solid #E0E0E0;
  }
  }
  &_icon-left{
    display: flex;
    justify-content:center;
    align-items: center;
    .btn__icon{
      order: -1;
      margin: 0px 0.417vw 0px 0px;
    }
    
  }
  .btn__icon{
    display: flex;
    justify-content:center;
    align-items: center;
    &>span{
      order: 2;
      margin: 0px 0 0px 0.694vw;
    }
  }
}
</style>