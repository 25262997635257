<script setup>
import {ref} from "vue";
import ShowProfile from "@/components/LKPage/LKWindow/ProfileWindow/ShowProfile.vue";
import EditProfile from "@/components/LKPage/LKWindow/ProfileWindow/EditProfile.vue";

const isEdit = ref(false)
</script>

<template>
  <div class="windows-wrapper">
    <ShowProfile
      v-if="!isEdit"
      :is-edit="isEdit"
      @update:is-edit="value => isEdit = value"
    />
    <EditProfile
      v-else
      :is-edit="isEdit"
      @update:is-edit="value => isEdit = value"
    />
  </div>
</template>

<style scoped lang="scss">

@import '@/assets/styles/vars.scss';

.windows-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  gap: 100px;

}

</style>