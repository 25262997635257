<script setup>
import {ref} from "vue";
import LKShowProfile from "@/pages/LKPage/LKWindow/LKProfileWindow/LKShowProfile.vue";
import LKEditProfile from "@/pages/LKPage/LKWindow/LKProfileWindow/LKEditProfile.vue";

const isEdit = ref(false)
</script>

<template>
  <div class="windows-wrapper">
    <LKShowProfile
      v-if="!isEdit"
      :is-edit="isEdit"
      @update:is-edit="value => isEdit = value"
    />
    <LKEditProfile
      v-else
      :is-edit="isEdit"
      @update:is-edit="value => isEdit = value"
    />
  </div>
</template>

<style scoped lang="scss">

@import '@/assets/styles/vars.scss';

.windows-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  gap: 100px;

}

</style>