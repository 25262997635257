<template>
    <div v-if="Store.notifications[0]" class="notification-container">
        <template v-if="Store.notifications[0]">
            <notificationItem  v-for="(notify, index) in Store.notifications" :item="notify" :key="index" />
        </template>
    </div>
</template>

<script>
import {useStore} from "@/store/index.js"
import notificationItem from './notificationItem.vue';
    export default {
        components:{
            notificationItem
        },
        data(){
            return {
                Store: useStore(),
            }
        },
        watch:{
            "Store.notifications.length"(){
                console.log(this.Store.notifications)
            }
        }
    }
</script>

<style>
.notification-container{
    position: fixed;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    padding: 2vh;
    z-index: 1000000;
    width: 50vh;
    min-height: 10%;
}
</style>