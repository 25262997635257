import {defineStore} from "pinia";
import axios from "axios";
import {setUser} from "@/composables/ModuleCommunication"
import {useStore} from "@/store/index";

export const useUser = defineStore({
    id: 'userStore',
    state: () => ({
        user: {
            userName: '',
            roleId: '',
            modules: [],
            userId: '',
            firstName: '',
            middleName: '',
            lastName: '',
            activeProject: 0,
            countryId: '',
            email: '',
            phone: '',
            roleName: '',
            image: '',
            language: ''
        },
        organizationMembers: []
    }),
    actions: {
        getFullName() {
            if (this.user.lastName === null) { this.user.lastName = ""; }
            if (this.user.firstName === null) { this.user.firstName = ""; }
            if (this.user.middleName === null) { this.user.middleName = ""; }

            return `${this.user.lastName} ${this.user.firstName} ${this.user.middleName}`
        },

        async updateUser(data) {
            await axios.patch('/globus/api/User/profile', data).then(async () => {
                await this.getUser()
            }).catch((error) => {
                console.log(error)
            })
        },

        async getUser(){
            await axios.get('/globus/api/User/settings').then((response) => {
                this.user = response.data

                setUser(this.user)
            });
        },
        clearUser() {
            setUser('') // очистка юзера в приложение 
            this.user = '' // очистка юзера в ЛК
        },

        async getOrganizationMembers() {
            await axios.get('/globus/api/User/organization/members').then((response) => {
                this.organizationMembers = response.data
            }).catch((error) => {
                console.log(error)
            })
        },

        async changeUser(data){
            const Store = useStore()
            try{
                console.log(data)
                await axios.patch(`/globus/api/User/profile`, data)
                Store.createNotify('Сохранено', '', 'succ')
                await this.getUser()
                return true
            } catch (e){
                console.log(e)
                Store.createNotify('Неудачно', 'Повторите попытку', '')
                return false
            }
        },

    }
});
