<template>
    <div class="modal-container">
        <div class="modal-background" @click="$emit('close')" />
        <div class="modal-content"
            :style="{width: width}"
        >
            <!-- <img height="20" width="20" class="btn-close" @click="$emit('close')" :src="require('@/assets/personal-area/x.svg')" alt=""> -->
            <SvgButton class="btn-close" height="20" width="20" name="x" @action="$emit('close')" />
            <slot>

            </slot>
        </div>

    </div>
</template>

<script>
import SvgButton from '@/components/common/svgButton.vue'
export default {
    components:{SvgButton},
    props: ['width']
}
</script>

<style lang="scss" scoped>
@import '@/style/styles.scss';
.btn-close{
    position: absolute;
    top: 3vh;
    right: 3vh;
    cursor: pointer;
}
.modal-container{
    position: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    top: 0;
    justify-content: center;
    left: 0;
    z-index: 1000;
}
.modal-background{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1001;
}
.modal-content{
    box-sizing: border-box;
    padding: 3vh;
    min-height: 15vh;
    min-width: 25vh;
    background-color: $background-color-content;
    border-radius: $border-radius;
    z-index: 1002;
    position: relative;
    width: fit-content;
}
</style>