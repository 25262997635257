<script setup>
import {defineProps, defineEmits, ref, useTemplateRef} from 'vue';
import SvgIcon from "@/components/common/svgButton.vue";

const props = defineProps({
  image: {
    type: String,
    default: null
  },
  isEdit: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:image'])

let img = ref('')
const imageInput = useTemplateRef('imageInput')

function addImg(){
  const reader = new FileReader();
  reader.readAsDataURL(imageInput.value.files[0])
  reader.onload = function() {
    img.value = reader.result
    emit('update:image', img.value)
  };
}

</script>

<template>
  <div class="avatar-wrapper">
    <img
      v-if="props.image"
      class="user-avatar"
      :src="props.image"
      alt=""
    >
    <SvgIcon
      v-else
      name="undefined-photo"
      class="user-avatar"
    />
    <label
      v-if="isEdit"
      class="edit-avatar"
    >
      <input
        ref="imageInput"
        style="display: none;"
        type="file"
        :accept="'.jpg, .png'"
        @input="addImg"
      >
      <SvgIcon
        name="plus-photo"
        width="20px"
        height="20px"
      />
    </label>
  </div>
</template>

<style scoped lang="scss">
.avatar-wrapper {
  position: relative;
  .user-avatar {
    border-radius: 50%;
    width: 100px;
    height: 100px;
  }

  .edit-avatar {
    position: absolute;
    background-color: white;
    border-radius: 50%;
    box-shadow: 1px 1px 10px 0px rgba(19, 30, 39, 0.15);
    cursor: pointer;
    overflow: hidden;

    bottom: -15px;
    right: -10px;

    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>